import { useImmer } from "use-immer";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getRegisterList, updateUserRegistration } from "..";

export const useRegistration = ({ enabled = false, id = null }) => {
  const queryClient = useQueryClient();

  const [status, setStatus] = useImmer("pending");
  const [isOpenRegistrationModal, setIsOpenRegistrationModal] = useImmer({
    id: null,
    isOpen: false,
  });

  const useRegisterListQuery = useQuery(
    ["NotificationList", status],
    () => getRegisterList(status),
    {
      onSuccess: (data) => {},
      enabled,
      staleTime: 0,
    }
  );

  const onUpdateUserRegistration = useMutation(updateUserRegistration, {
    onSuccess: (data) => {
      queryClient?.invalidateQueries(["NotificationList", status]);
      queryClient?.invalidateQueries(["adminDashBoardData"]);
      setIsOpenRegistrationModal((draft) => {
        draft.isOpen = false;
        draft.id = null;
      });
    },
  });

  const onHandleRegistrationStatusUpdate = ({ id }) => {
    onUpdateUserRegistration?.mutate(id);
  };

  return {
    status,
    useRegisterListQuery,
    isOpenRegistrationModal,
    setStatus,
    setIsOpenRegistrationModal,
    onHandleRegistrationStatusUpdate,
  };
};
