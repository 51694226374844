import React from "react";
import { App } from "App";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import { PaymentModal } from "shared";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/project/styles/common.style.scss";

// Material Dashboard 2 React Context Provider

import { MaterialUIControllerProvider } from "context";

const container = document.getElementById("app");
const root = createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: false,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <ToastContainer
          rtl={false}
          draggable
          pauseOnHover
          theme="light"
          closeOnClick
          hideProgressBar
          autoClose={1000}
          pauseOnFocusLoss
          newestOnTop={false}
          position="top-right"
        />
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </QueryClientProvider>
);
