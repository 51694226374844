import React from "react";
import { useEffect } from "react";
import { useDelete } from "shared";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";
import {
  getNotificationList,
  updateNotification,
  createNotification,
  getNotificationById,
  deleteNotification,
} from "..";
import { useQuery, useMutation, useQueryClient } from "react-query";

export const useNotification = ({ enabled = false, id = null }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { deleteItem, onHandleDeleteItemDetails, resetDeleteData } = useDelete();

  const [notification, setNotification] = useImmer({ title: "", description: "" });
  const [currentNotification, setcurrentNotification] = useImmer("");

  const useNotificationQuery = useQuery(["NotificationList"], getNotificationList, {
    onSuccess: (data) => {},
    enabled,
  });

  const useNotificationDetailQuery = useQuery(
    ["NotificationList", id],
    () => getNotificationById(id),
    {
      onSuccess: (data) => {},
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (useNotificationDetailQuery?.data) {
      const { data } = useNotificationDetailQuery?.data || {};
      setNotification((draft) => {
        draft = data;
        return draft;
      });

      setcurrentNotification(data?.title);
    }
  }, [useNotificationDetailQuery?.data]);

  const createNotifications = useMutation(createNotification, {
    onSuccess: () => {
      queryClient?.invalidateQueries(["NotificationList"]);
      navigate("..", { replace: true });
    },
  });

  const updateNotifications = useMutation(updateNotification, {
    onSuccess: () => {
      queryClient?.invalidateQueries(["NotificationList"]);
      navigate("..", { replace: true });
    },
  });

  const deleteNotifications = useMutation(deleteNotification, {
    onSuccess: () => {
      queryClient?.invalidateQueries(["NotificationList"]);
      navigate("..", { replace: true });
    },
    onSettled: () => {
      resetDeleteData();
    },
  });

  const onChangeNotification = (e) => {
    const { value, name } = e?.target;
    setNotification((draft) => {
      draft[name] = value;
    });
  };

  const onHandleUpdateOrCreateNotification = () => {
    const { id, title, description } = notification;
    if (id) {
      updateNotifications.mutate({
        id,
        title,
        description,
        _method: "PUT",
      });
    } else {
      createNotifications?.mutate(notification);
    }
  };

  const onHandleDeleteNotification = () => {
    deleteNotifications?.mutate(deleteItem?.id);
  };

  const isLoadingNoti_detail = useNotificationDetailQuery?.isLoading;

  return {
    deleteItem,
    notification,
    currentNotification,
    useNotificationQuery,
    isLoadingNoti_detail,
    resetDeleteData,
    onChangeNotification,
    onHandleDeleteNotification,
    onHandleDeleteItemDetails,
    onHandleUpdateOrCreateNotification,
  };
};
