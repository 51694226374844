import { useState } from "react";
import { useValidation } from "..";
import { useImmer } from "use-immer";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { Link } from "react-router-dom";
import MDInput from "components/MDInput";
import MuiLink from "@mui/material/Link";
import Switch from "@mui/material/Switch";
import MDButton from "components/MDButton";
import { useAppScopeContext } from "master";
import { loginValidationSchema } from "master";
import MDTypography from "components/MDTypography";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { LoaderButton } from "shared";

export const Login = () => {
  const [isShowpassword, setIsShowPassword] = useImmer(false);
  const { isLoadingUserLogin, loginCredential, onHandleUserLogin, onChangeUserCredential } =
    useAppScopeContext();

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const { formik, getErrorMessage, isErrorField } = useValidation({
    state: loginCredential,
    handleSubmit: onHandleUserLogin,
    validationSchema: loginValidationSchema,
  });

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Login
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                name="email"
                label="Email"
                fullWidth
                error={isErrorField("email")}
                helperText={getErrorMessage("email")}
                value={loginCredential?.email}
                onChange={onChangeUserCredential}
              />
            </MDBox>
            <MDBox mb={2} position={"relative"}>
              <MDInput
                name="password"
                label="Password"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-input": {
                    pr: 5,
                  },
                }}
                value={loginCredential?.password}
                type={isShowpassword ? "text" : "password"}
                error={isErrorField("password")}
                helperText={getErrorMessage("password")}
                onChange={onChangeUserCredential}
              />
              {isShowpassword ? (
                <VisibilityIcon
                  sx={{ position: "absolute", top: "11px", right: "9px" }}
                  onClick={() => setIsShowPassword(false)}
                />
              ) : (
                <VisibilityOffIcon
                  sx={{ position: "absolute", top: "11px", right: "9px" }}
                  onClick={() => setIsShowPassword(true)}
                />
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <LoaderButton
                btnName={"sign in"}
                fullWidth={true}
                isLoading={isLoadingUserLogin}
                onClick={formik?.handleSubmit}
              />
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/auth/register"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
};
