import React from "react";
import { SalesListPage } from "..";
import { Route, Routes } from "react-router-dom";

export const Sales = () => {
  return (
    <Routes>
      <Route path="/" element={<SalesListPage />} />;
    </Routes>
  );
};
