import React from "react";
import { Stack, Typography } from "@mui/material";
import {
  ContactSection,
  CategorySection,
  BannerSectionOne,
  FooterComponent,
  ProductAnimation,
  CardDetailSection,
} from ".";

export const LandingPage = () => {
  return (
    <Stack pb={10} sx={{ backgroundColor: "white !important" }}>
      <BannerSectionOne />
      <Stack px={5}>
        <Stack
          my={4}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          flexWrap={"wrap"}
          className="category-heading"
        >
          <Typography fontSize={40} className="label">
            Shop By
          </Typography>
          <Typography fontSize={40} fontWeight={600} className="label" ml={1}>
            Collection
          </Typography>
        </Stack>
        <CategorySection />
        <ProductAnimation />
        <CardDetailSection />
        <ContactSection />
        <FooterComponent />
      </Stack>
    </Stack>
  );
};
