import { useEffect } from "react";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";
import { onAlertPurchaseDetails, showErrorMessage } from "utils";
import { getProductListData, purchaseProduct, getUserAreaList } from "..";
import { useQuery, useMutation, useQueryClient } from "react-query";

const initFilter = {
  area_id: null,
  distributor_id: null,
};

export const useProductData = ({ load = false, fromDahBoard }) => {
  const queryClient = useQueryClient();

  const [productData, setProductData] = useImmer(null);
  const [productFilter, setProductFilter] = useImmer(initFilter);
  const [isOpenCheckOutModal, setIsOpenCheckOutModal] = useImmer(false);

  const useProductListQuery = useQuery(
    ["product_list_data"],
    () => getProductListData(fromDahBoard),
    {
      enabled: load,
      staleTime: 0,
      refetchOnWindowFocus: true,
      onSuccess: (data) => {},
    }
  );

  const purchaseProducts = useMutation(purchaseProduct, {
    onError: (data) => {
      onHandleProductDetails({ productDetails: null });
      showErrorMessage({ message: data?.response?.data?.message });
      queryClient?.invalidateQueries(["PurchaseList"]);
    },
    onSuccess: () => {
      onAlertPurchaseDetails({ message: "Admin will confirm your purchase" });
      onHandleProductDetails({ productDetails: null });
    },
  });

  const onHandlePurchaseProducts = () => {
    const { id, price } = productData || {};
    purchaseProducts?.mutate({ product_id: id });
  };

  const onHandleProductDetails = ({ productDetails }) => {
    setProductData((draft) => {
      draft = productDetails;
      return draft;
    });
  };

  const onChangeFilterParameter = (e) => {
    const { value, name } = e?.target;
    setProductFilter((draft) => {
      draft[name] = value;
    });
  };

  return {
    productData,
    productFilter,
    useProductListQuery,
    isOpenCheckOutModal,
    onChangeFilterParameter,
    setIsOpenCheckOutModal,
    onHandleProductDetails,
    onHandlePurchaseProducts,
  };
};
