// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";
import {
  Item,
  Invest,
  Area,
  Sales,
  Category,
  Purchase,
  ProductPage,
  CustomerList,
  Distributors,
  SalesApprove,
  Notification,
  ItemDistributor,
  Registration,
} from "modules";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    pathName: "dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customerList",
    icon: <i className="bi bi-people-fill"></i>,
    route: "/customerList/*",
    pathName: "customerList",
    isVisible: "admin",
    component: <CustomerList />,
  },
  {
    type: "collapse",
    name: "Registration",
    key: "registrationsList",
    icon: <i className="bi bi-person"></i>,
    route: "/registrationsList/*",
    pathName: "registrationsList",
    isVisible: "admin",
    component: <Registration />,
  },
  {
    type: "collapse",
    name: "Distributors",
    key: "distributorList",
    icon: <i className="bi bi-truck"></i>,
    route: "/distributorList/*",
    pathName: "distributorList",
    isVisible: "admin",
    component: <Distributors />,
  },
  {
    type: "collapse",
    name: "Items",
    key: "itemList",
    icon: <i className="bi bi-cart-dash"></i>,
    route: "/itemList/*",
    pathName: "itemList",
    isVisible: "admin",
    component: <Item />,
  },
  {
    type: "collapse",
    name: "Category",
    key: "categoryList",
    icon: <i className="bi bi-tag"></i>,
    route: "/categoryList/*",
    pathName: "categoryList",
    isVisible: "admin",
    component: <Category />,
  },
  // .......
  {
    type: "collapse",
    name: "Sales",
    key: "salesList",
    icon: <i className="bi bi-file-bar-graph-fill"></i>,
    route: "/salesList/*",
    pathName: "salesList",
    isVisible: "admin",
    component: <Sales />,
  },
  // ..........
  {
    type: "collapse",
    name: "Product",
    key: "Product",
    icon: <i className="bi bi-list"></i>,
    route: "/Product/*",
    pathName: "Product",
    isVisible: "user",
    component: <ProductPage />,
  },
  {
    type: "collapse",
    name: "Invest",
    key: "Invest",
    icon: <i className="bi bi-wallet"></i>,
    route: "/Invest/*",
    pathName: "Invest",
    isVisible: "user",
    component: <Invest />,
  },
  {
    type: "collapse",
    name: "Purchase List",
    key: "purchaseList",
    icon: <i className="bi bi-bag"></i>,
    route: "/purchaseList/*",
    pathName: "purchaseList",
    isVisible: "user",
    component: <Purchase />,
  },
  {
    type: "collapse",
    name: "SalesApprove",
    key: "approveSales",
    icon: <i className="bi bi-wallet"></i>,
    route: "/approveSales",
    pathName: "approveSales",
    isVisible: "admin",
    component: <SalesApprove />,
  },
  {
    type: "collapse",
    name: "Area",
    key: "areaList",
    icon: <i className="bi bi-geo-alt-fill"></i>,
    route: "/areaList/*",
    pathName: "areaList",
    isVisible: "admin",
    component: <Area />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notification",
    icon: <i className="bi bi-bell"></i>,
    route: "/notification*",
    pathName: "notification",
    isVisible: "admin",
    component: <Notification />,
  },
  // {
  //   type: "collapse",
  //   name: "Store Assign",
  //   key: "itemDetails",
  //   icon: <i className="bi bi-shop"></i>,
  //   route: "/itemDetails*",
  //   pathName: "itemDetails",
  //   isVisible: "admin",
  //   component: <ItemDistributor />,
  // },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables/*",
    pathName: "tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing/*",
    pathName: "billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl/*",
    pathName: "rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications/*",
    pathName: "notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile/*",
    pathName: "profile",
    component: <Profile />,
  },
];

export default routes;
