import { useEffect } from "react";
import { useDelete } from "shared";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";
import {
  getAreaListData,
  getAreaListById,
  AREA_LIST,
  createAreas,
  updateAreaById,
  deleteAreas,
} from "..";
import { useQuery, useMutation, useQueryClient } from "react-query";

const initArea = { name: "" };

export const useArea = ({ enabled = false, id = null }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { deleteItem, onHandleDeleteItemDetails, resetDeleteData } = useDelete();

  const [currentArea, setCurrentArea] = useImmer("");
  const [selectedArea, setSelectedArea] = useImmer(initArea);

  const useAreaDetailQuery = useQuery([AREA_LIST, id], () => getAreaListById(id), {
    onSuccess: (data) => {},
    enabled: !!id,
    onSettled: () => {},
  });

  useEffect(() => {
    if (useAreaDetailQuery.data) {
      const { data } = useAreaDetailQuery?.data || {};
      setSelectedArea((draft) => {
        draft.name = data?.name;
        return draft;
      });
      setCurrentArea((draft) => {
        draft = data?.name;
        return draft;
      });
    }
  }, [useAreaDetailQuery.data]);

  const createArea = useMutation(createAreas, {
    onSuccess: () => {
      queryClient?.invalidateQueries([AREA_LIST]);
      navigate("..", { replace: true });
    },
  });
  const updateArea = useMutation(updateAreaById, {
    onSuccess: () => {
      queryClient?.invalidateQueries([AREA_LIST]);
      navigate("..", { replace: true });
    },
  });

  const deleteArea = useMutation(deleteAreas, {
    onSuccess: () => {
      queryClient?.invalidateQueries([AREA_LIST]);
    },
    onSettled: () => {
      resetDeleteData();
    },
  });

  const onHandelDeleteArea = () => {
    deleteArea?.mutate(deleteItem?.id);
  };

  const onHandleCreateOrUpdateArea = () => {
    if (id) {
      updateArea?.mutate({ ...selectedArea, _method: "PUT", id });
    } else {
      createArea?.mutate(selectedArea);
    }
  };

  const onChangeAreaDetail = (event) => {
    const { name, value } = event?.target;
    setSelectedArea((draft) => {
      draft[name] = value;
      return draft;
    });
  };

  return {
    deleteItem,
    currentArea,
    selectedArea,
    resetDeleteData,
    onChangeAreaDetail,
    onHandelDeleteArea,
    onHandleDeleteItemDetails,
    onHandleCreateOrUpdateArea,
  };
};
