import * as Yup from "yup";

export const registrationValidationSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Username must be at least 3 characters")
    .max(20, "Username cannot be longer than 20 characters")
    .required("Username is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  phone_no: Yup.string()
    .nullable()
    .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits") // Adjust regex for your specific needs
    .required("Phone number is required"), // Assuming referral can be null or empty
  address: Yup.string().required("Address is required"),
  adhar_card: Yup.mixed()
    .required("Aadhar  is required")
    .test("fileSize", "File too large, max size is 1MB", (value) => value && value.size <= 1000000)
    .test(
      "fileType",
      "Unsupported file format, only JPEG, PNG, and PDF",
      (value) => value && ["image/jpeg", "image/png", "application/pdf"].includes(value.type)
    ),
  pan_card: Yup.mixed()
    .required("PAN card upload is required")
    .test("fileSize", "File too large, max size is 1MB", (value) => value && value.size <= 1000000)
    .test(
      "fileType",
      "Unsupported file format, only JPEG, PNG, and PDF",
      (value) => value && ["image/jpeg", "image/png", "application/pdf"].includes(value.type)
    ),
});
