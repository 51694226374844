import {
  earringCtgry,
  necklaceCtgry,
  Anklets,
  Breaslet,
  Pendants1,
  Ring,
  Earrings2,
  Bangles,
  neckace,
} from "assets";

export const imageArray = [
  "https://www.josalukkasonline.com/Media/CMS/jos-alukkas-gold-jewellery-20240402185753433872.webp",
  "https://www.josalukkasonline.com/Media/CMS/jos-alukkas-necklace-20240402185443911750.webp",
  "https://www.josalukkasonline.com/Media/CMS/jos-alukkas-earrings-20240402185011753893.webp",
  "https://www.josalukkasonline.com/Media/CMS/jos-alukkas-bracelet-20240402184937988329.webp",
];
export const category = [
  { image: earringCtgry },
  "https://www.josalukkasonline.com/Media/CMS/jos-alukkas-necklace-20240402185443911750.webp",
  "https://www.josalukkasonline.com/Media/CMS/jos-alukkas-earrings-20240402185011753893.webp",
  "https://www.josalukkasonline.com/Media/CMS/jos-alukkas-bracelet-20240402184937988329.webp",
];

// card image :- https://www.joyalukkas.in/media/wysiwyg/e-gift-card--desk-top_1.jpg?auto=webp&format=pjpg&quality=85
export const sectionImages = [
  {
    type: "RINGS",
    discription: "A ring is a promise kept.",
    image: Ring,
  },
  {
    type: "EARRINGS",
    discription: "Let your earrings do the talking.",
    image: Earrings2,
  },
  {
    type: "BANGLES",
    discription: "Bangles: Where tradition meets style.",
    image: Bangles,
  },
  {
    type: "BRACELET",
    discription: "A bracelet for every occasion.",
    image: Breaslet,
  },
  {
    type: "NECKLACE",
    discription: "Adorn your neck with elegance.",
    image: neckace,
  },
  {
    type: "PENDANTS",
    discription: "Pendants: Small wonders, big impact.",
    image: Pendants1,
  },
];

export const policyDetails = [
  {
    title: "Fair Price",
    subTitle: "Policy",
    discription: "Beautiful designs crafted in impeccable gold at reasonable making...",
  },
  {
    title: "Responsibly",
    subTitle: "Sourced Products",
    discription: "All our jewellery adheres to responsible acquisition standards...",
  },
  {
    title: "Tested & Certified",
    subTitle: "Golds",
    discription: "Every diamond pass through 28 internal quality tests with IGI- GIA...",
  },
  {
    title: "Complete",
    subTitle: "Transparency",
    discription: "At the Maizon Jewellers online store, every piece of jewellery...",
  },
];
