import Swal from "sweetalert2";
import { toast } from "react-toastify";

let timerInterval;

export const getPaymentStatus = () => {
  const paymentStatus = localStorage?.getItem("paymentStatus");
  if (paymentStatus !== "undefined") {
    const localPaymentStatus = JSON?.parse(paymentStatus);
    const isDisabled = localPaymentStatus?.data === 0 || localPaymentStatus?.data === null;
    return isDisabled;
  } else {
    return true;
  }
};

export const getToken = () => {
  const localUserData = JSON.parse(localStorage.getItem("userData"));
  const token = localUserData?.token;
  return token;
};

export const hasRemoveUserData = () => {
  localStorage?.removeItem("userData");
};

export const trimText = (str = "", length = 35, tail = "...") => {
  if (str?.length > length) {
    return str.substring(0, length - tail.length) + tail;
  } else {
    return str;
  }
};

export const isActiveSidenav = ({ activeKey, location }) => {
  const currentLocation = location.pathname.replace("/", "");
  return currentLocation.includes(activeKey);
};

export const handleNavigation = (path, navigate) => {
  setTimeout(() => {
    navigate(path, { replace: true });
  }, 300);
};

export const toGetNumberFormat = (data) => {
  if (typeof data === "number") {
    return data;
  }

  const numberString = data?.replace(/[^0-9.-]+/g, "");
  const number = parseFloat(numberString);
  return isNaN(number) ? "" : number;
};

export const onAlertPurchaseDetails = ({ message, navigate }) => {
  Swal.fire({
    title: "confirm",
    icon: "success",
    html: message,
    timer: 4000,
    timerProgressBar: true,
    didOpen: () => {
      const timer = Swal.getPopup().querySelector("b");
      timerInterval = setInterval(() => {
        timer.textContent = `${Swal.getTimerLeft()}`;
      }, 4000);
    },
    willClose: () => {
      clearInterval(timerInterval);
      if (navigate) {
        navigate();
      }
    },
  }).then((result) => {
    if (result.dismiss === Swal.DismissReason.timer) {
      if (navigate) {
        navigate();
      }
    }
  });
};

export const showSuccessPopUp = ({ text, title }) => {
  Swal.fire({
    title,
    icon: "success",
    text,
    showConfirmButton: false,
    timer: 1500,
  });
};

export const successMessage = ({ message }) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export const showErrorMessage = ({ message }) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export const showWarningMessage = ({ message }) => {
  toast.warning(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export const loadRazorpayScript = () => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};
