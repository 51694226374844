import { Axios } from "utils";

export const getProductListData = async (fromDahBoard) => {
  const res = await Axios.get(`/user/get_item_by_balance${fromDahBoard ? "?type=1" : ""}`);
  return res.data;
};

export const purchaseProduct = async (payload) => {
  const res = await Axios.post("/user/purchase_item", payload);
  return res.data;
};

export const getUserAreaList = async (payload) => {
  const res = await Axios.get("user/get_area", payload);
  return res.data;
};

export const getUserDistributorlist = async (payload) => {
  const res = await Axios.post("/user/get_distributor", payload);
  return res.data;
};
