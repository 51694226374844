import React from "react";
import { EditOrCreateCategory, CategoryList } from ".";
import { Routes, Route } from "react-router-dom";

export const Category = () => {
  return (
    <Routes>
      <Route path={"/"} element={<CategoryList />} />
      <Route path={"/Category/create"} element={<EditOrCreateCategory />} />
      <Route path={"/Category/edit/:id"} element={<EditOrCreateCategory />} />
    </Routes>
  );
};
