/* eslint-disable react/prop-types */

import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import { useArea, useAreaListQuery } from "..";
import { useNavigate } from "react-router-dom";
import { ReactTable, DeleteModal } from "shared";
import { Stack, Grid, Card } from "@mui/material";
import { useMaterialUIController } from "context";
import { handleNavigation } from "utils";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

export const AreaList = () => {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const route = [{ name: "Areas" }];

  const { deleteItem, resetDeleteData, onHandelDeleteArea, onHandleDeleteItemDetails } = useArea({
    enabled: true,
  });

  const { query: data } = useAreaListQuery({ enabled: true });

  const ActionButton = ({ row }) => {
    const { id, name } = row.original;

    return (
      <Stack direction={"row"} alignItems={"center"} gap={1} key={`${id}_ActionButton`}>
        <MDButton
          variant="text"
          color={darkMode ? "white" : "dark"}
          sx={{ padding: "4px !important" }}
          onClick={() => {
            handleNavigation(`./area/edit/${id}`, navigate);
          }}
        >
          <Icon>edit</Icon>
        </MDButton>
        <MDButton
          variant="text"
          color="error"
          onClick={() => onHandleDeleteItemDetails({ name, id })}
        >
          <Icon>delete</Icon>
        </MDButton>
      </Stack>
    );
  };

  const columns = [
    {
      Header: "Area",
      accessor: "name",
    },

    {
      Header: "Action",
      align: "center",
      accessor: "id",
      Cell: ActionButton,
      isNotVisibleSort: true,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbRoute={route} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <MDTypography variant="h6" color="white">
                    Area List
                  </MDTypography>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={() => handleNavigation("./area/create", navigate)}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;Create
                  </MDButton>
                </Stack>
              </MDBox>
              <MDBox pt={3}>
                <ReactTable
                  noEndBorder
                  isSorted={true}
                  canSearch={true}
                  showTotalEntries={true}
                  entriesPerPage={{ defaultValue: 15 }}
                  table={{ columns, data: data ? data : [] }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <DeleteModal
        text={"Distributor name"}
        itemName={deleteItem?.name}
        isOpen={deleteItem?.name}
        onConfirm={onHandelDeleteArea}
        handleClose={resetDeleteData}
      />
    </DashboardLayout>
  );
};
