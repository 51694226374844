import React from "react";
import { InvestMain } from ".";

export const Invest = () => {
  return (
    <div>
      <InvestMain />
    </div>
  );
};
