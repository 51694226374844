import { useEffect } from "react";
import { useDelete } from "shared";
import { useImmer } from "use-immer";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { getCustomerList, CUSTOMER_LIST } from "..";

const initDistributor = { name: "", area_id: "" };

export const useCustomer = ({ enabled = false, id = null }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { deleteItem, onHandleDeleteItemDetails, resetDeleteData } = useDelete();

  const useCustomerListQuery = useQuery([CUSTOMER_LIST], () => getCustomerList(), {
    onSuccess: () => {},
    onSettled: () => {},
    enabled,
    staleTime: 0,
  });

  return {
    useCustomerListQuery,
  };
};
