import React from "react";
import MDBox from "components/MDBox";
import { useNotification } from "..";
import MDButton from "components/MDButton";
import { HeaderSection, Loader } from "shared";
import { trimText, handleNavigation } from "utils";
import { useParams, useNavigate } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Card, Grid, Stack, TextField, Typography } from "@mui/material";

export const CreateOrUpdateNotification = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    notification,
    currentNotification,
    isLoadingNoti_detail,
    onChangeNotification,
    onHandleUpdateOrCreateNotification,
  } = useNotification({ id });

  const route = [
    { navLink: "notification", name: "Notification List" },
    { name: id ? trimText(currentNotification) : "Create Item" },
  ];

  if (isLoadingNoti_detail) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbRoute={route} />
      <Card sx={{ p: 3 }}>
        <HeaderSection currentName={id ? currentNotification : "Create Notification"} />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Typography className="label_font" fontWeight={500}>
                Notification*
              </Typography>
              <Stack mb={2}>
                <TextField
                  name="title"
                  value={notification?.title}
                  onChange={onChangeNotification}
                />
              </Stack>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography className="label_font" fontWeight={500}>
                Description*
              </Typography>
              <Stack mb={2}>
                <TextField
                  name="description"
                  value={notification?.description}
                  onChange={onChangeNotification}
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack position={"relative"} className="item_moduel-footer" mt={3}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} gap={2}>
              <MDButton
                variant="gradient"
                color="light"
                onClick={() => handleNavigation("..", navigate)}
              >
                &nbsp;Cancel
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                onClick={onHandleUpdateOrCreateNotification}
              >
                &nbsp;{id ? "update" : "Create"}
              </MDButton>
            </Stack>
          </Stack>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
};
