import { Axios } from "utils";

export const getRegisterList = async (status) => {
  const res = await Axios.get(`admin/registration-list?status=${status}`);
  return res.data;
};

export const updateUserRegistration = async (id) => {
  const res = await Axios.post(`admin/approve-list/${id}`);
  return res.data;
};
