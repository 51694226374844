import React from "react";
import { NotificationList, CreateOrUpdateNotification } from ".";
import { Routes, Route } from "react-router-dom";

export const Notification = () => {
  return (
    <Routes>
      <Route path={"/"} element={<NotificationList />} />
      <Route path={"/create"} element={<CreateOrUpdateNotification />} />
      <Route path={"/edit/:id"} element={<CreateOrUpdateNotification />} />
    </Routes>
  );
};
