import React from "react";
import { useQuery } from "react-query";
import { getAreaListData } from "..";

export const useAreaListQuery = ({ enabled }) => {
  const useAreaListQuery = useQuery(["userAreList"], getAreaListData, {
    enabled,
  });

  const { data: { data } = {} } = useAreaListQuery || {};

  return {
    query: data,
    isLoading: useAreaListQuery?.isLoading,
  };
};
