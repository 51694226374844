import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Loader, ReactTable, StatusBtn } from "shared";
import { Stack, Grid, Card, Typography } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { usePurchase } from ".";
import { trimText } from "utils";

export const PurchaseListPage = () => {
  const { usePurchaseListQuery } = usePurchase({ enabled: true });
  const { data: purchaseList } = usePurchaseListQuery?.data || {};

  const route = [{ name: " Purchase List" }];

  const columns = [
    {
      Header: "Product",
      accessor: "product_name",
      Cell: ({ row }) => {
        return trimText(row?.original?.product_name);
      },
    },
    {
      Header: "Price",
      accessor: "price",
    },
    {
      Header: " Status",
      accessor: "status",
      Cell: ({ row }) => {
        const { status } = row?.original;
        const isCompleted = status === "completed";

        return (
          <Typography
            p={1}
            fontSize={"13px"}
            className={`status-btn ${isCompleted ? "approved" : "pending"}`}
          >
            {isCompleted ? "Approved" : "Pending"}
          </Typography>
        );
      },
    },
  ];

  if (usePurchaseListQuery?.isLoading) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbRoute={route} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <MDTypography variant="h6" color="white">
                    Purchase List
                  </MDTypography>
                </Stack>
              </MDBox>
              <MDBox pt={3}>
                {purchaseList && (
                  <ReactTable
                    noEndBorder
                    isSorted={true}
                    canSearch={true}
                    showTotalEntries={true}
                    entriesPerPage={{ defaultValue: 15 }}
                    table={{ columns, data: purchaseList }}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};
