import React from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { handleNavigation, trimText } from "utils";
import MDTypography from "components/MDTypography";
import { useParams, useNavigate } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { TextField, MenuItem, Stack, Card, Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useDistributor } from "..";
import { HeaderSection } from "shared";

export const CreateOrUpdateDistributors = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    useAreaList,
    currentDistributor,
    selectedDistributor,
    onChangeDistributorDetail,
    onHandleCreateOrUpdateDistributor,
  } = useDistributor({ id });

  const route = [
    { navLink: "distributorList", name: "Distributors List" },
    { name: id ? trimText(currentDistributor) : "Create Distributor" },
  ];

  const { data: { data: areaList } = {} } = useAreaList || {};
  const selectedArea = areaList?.find((area) => area?.id === selectedDistributor?.area_id)?.name;

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbRoute={route} />
      <MDBox pt={6} pb={3}>
        <Card sx={{ px: 2, py: 3, pb: "35px" }}>
          <HeaderSection currentName={id ? trimText(currentDistributor) : "Create Item"} />
          <Grid container mt={2} spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <MDTypography variant="caption" color="text" sx={{ fontWeight: 500 }}>
                Distributor Name*
              </MDTypography>
              <TextField
                fullWidth
                name="name"
                placeholder={"Type here..."}
                value={selectedDistributor?.name}
                onChange={onChangeDistributorDetail}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MDTypography variant="caption" color="text" sx={{ fontWeight: 500 }}>
                Area*
              </MDTypography>
              <TextField
                select
                name="area_id"
                fullWidth
                placeholder={"Type here..."}
                value={selectedArea ? selectedArea : ""}
                sx={{
                  "&  .MuiInputBase-input": {
                    height: "44px !important",
                  },
                }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value) =>
                    value === "" ? <span style={{ opacity: 0.4 }}>Select</span> : value,
                }}
                onChange={onChangeDistributorDetail}
              >
                <MenuItem value={""} selected>
                  select
                </MenuItem>
                {areaList?.map((area, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={area?.id}
                      selected={selectedDistributor?.area === area?.id}
                    >
                      {area?.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>
          <Stack position={"relative"} className="item_moduel-footer" mt={4}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} gap={2}>
              <MDButton
                variant="gradient"
                color="light"
                onClick={() => handleNavigation("..", navigate)}
              >
                &nbsp;Cancel
              </MDButton>
              <MDButton variant="gradient" color="info" onClick={onHandleCreateOrUpdateDistributor}>
                &nbsp;{id ? "update" : "Create"}
              </MDButton>
            </Stack>
          </Stack>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};
