import React from "react";
import routes from "routes";
import { Routes, Route } from "react-router-dom";

export const MainRoutes = () => {
  const getRoutes = () =>
    routes?.map((route) => {
      if (route?.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route path={route?.route} element={route?.component} key={route?.key} />;
      }

      return null;
    });

  return <Routes>{getRoutes()}</Routes>;
};
