import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";

export const ContactSection = () => {
  return (
    <Stack>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 4,
          py: 3,
          px: 1,
          borderRadius: 3,
          border: "1px solid #e7e8e9",
          boxShadow: "1px 5px 15px -12px rgba(0, 0, 0, 0.75)",
        }}
      >
        <Grid item xs={12} md={3}>
          <Stack>
            <Typography>Subscribe to Maizon Gold</Typography>
            <Typography fontSize={13}>
              Get Promotions, inspirations and the latest news about brands and jewellery items
              directly in your inbox
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={9}>
          <Stack direction={"row"}>
            <TextField fullWidth />
            <Button
              variant="contained"
              sx={{
                ml: 2,
                py: 1.8,
                color: "white !important",
                backgroundColor: "#313126 !important",
              }}
            >
              SUBSCRIBE
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};
