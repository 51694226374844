import React from "react";
import { PaymentModal } from "shared";
import { useAppScopeContext } from "master";
import { Navigate } from "react-router-dom";
import { getPaymentStatus } from "utils";
import { useImmer } from "use-immer";

export const ProtectedRoute = ({ children }) => {
  const { getAuthToken, isOpenPaymentModal, setIsOpenPaymentModal, hasUserOrAdminPermission } =
    useAppScopeContext();

  const token = getAuthToken();
  const hasUser = hasUserOrAdminPermission();
  const hasPaymentNotComplete = getPaymentStatus();

  const handleClose = () => {
    setIsOpenPaymentModal(false);
  };

  return token ? (
    <>
      {hasPaymentNotComplete && (
        <PaymentModal
          isOpen={hasUser === "user" && isOpenPaymentModal}
          onClose={() => {
            handleClose();
          }}
        />
      )}
      {children}
    </>
  ) : (
    <Navigate to="/auth/login" />
  );
};
