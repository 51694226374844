import React from "react";
import { TextField, MenuItem } from "@mui/material";

export const InputControlV2 = ({ option = [], value, type, ...rest }) => {
  let inputElement = "";
  switch (type) {
    case "dropdown":
      inputElement = (
        <TextField
          {...rest}
          select
          fullWidth
          value={value}
          sx={{
            "&  .MuiInputBase-input": {
              height: "44px !important",
            },
          }}
          SelectProps={{
            displayEmpty: true,
            renderValue: (selectedValue) => {
              if (!selectedValue) {
                return <span style={{ opacity: 0.4 }}>Select...</span>;
              }
              const selectedOption = option?.find((opt) => opt?.id === selectedValue);
              return selectedOption ? selectedOption.name : selectedValue;
            },
          }}
        >
          <MenuItem value={""} selected>
            select
          </MenuItem>
          {option?.map((optionDetail, index) => {
            return (
              <MenuItem key={index} value={optionDetail?.id} selected={value === optionDetail?.id}>
                {optionDetail?.name}
              </MenuItem>
            );
          })}
        </TextField>
      );

      break;

    default:
      break;
  }
  return inputElement;
};
