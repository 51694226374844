import { getPurchaseList } from "..";
import { useQuery } from "react-query";

export const usePurchase = ({ enabled }) => {
  const usePurchaseListQuery = useQuery(["PurchaseList"], getPurchaseList, {
    onSuccess: (data) => {},
    enabled,
    staleTime: 0,
  });
  return {
    usePurchaseListQuery,
  };
};
