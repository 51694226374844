import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import { Link } from "react-router-dom";
import MDInput from "components/MDInput";
import { useValidation, LoaderButton } from "shared";
import MDButton from "components/MDButton";
import { Checkbox, Stack, TextField, Typography } from "@mui/material";
import MDTypography from "components/MDTypography";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { useAppScopeContext, registrationValidationSchema } from "master";

export const SignUp = () => {
  const {
    isLoadingUserRegistration,
    onHandleUserRegistration,
    onChangeUserRegistration,
    registrationCredential,
    uploadUserCertificate,
  } = useAppScopeContext();

  const { formik, getErrorMessage, isErrorField } = useValidation({
    state: registrationCredential,
    handleSubmit: onHandleUserRegistration,
    validationSchema: registrationValidationSchema,
  });

  const { username, password, phone_no, email, address } = registrationCredential;

  return (
    <CoverLayout image={bgImage} footerPosition="relative">
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Register
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                fullWidth
                type="text"
                label="Name"
                name="username"
                value={username}
                variant="standard"
                error={isErrorField("username")}
                helperText={getErrorMessage("username")}
                onChange={onChangeUserRegistration}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                fullWidth
                name="email"
                type="email"
                label="Email"
                value={email}
                variant="standard"
                error={isErrorField("email")}
                helperText={getErrorMessage("email")}
                onChange={onChangeUserRegistration}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                fullWidth
                name="password"
                type="password"
                label="Password"
                value={password}
                variant="standard"
                error={isErrorField("password")}
                helperText={getErrorMessage("password")}
                onChange={onChangeUserRegistration}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                fullWidth
                type="text"
                name="address"
                value={address}
                variant="standard"
                label="Address"
                error={isErrorField("address")}
                helperText={getErrorMessage("address")}
                onChange={onChangeUserRegistration}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                fullWidth
                type="text"
                name="phone_no"
                value={phone_no}
                variant="standard"
                label="Phone number"
                error={isErrorField("phone_no")}
                helperText={getErrorMessage("phone_no")}
                onChange={onChangeUserRegistration}
              />
            </MDBox>
            <MDBox mb={2}>
              <Stack>
                <Typography fontSize={14} position={"relative"} top={22}>
                  Aadhar card*
                </Typography>
                <MDInput
                  type="file"
                  fullWidth
                  margin="normal"
                  variant="filled"
                  name="adhar_card"
                  className="fileUpload-input"
                  error={isErrorField("adhar_card")}
                  helperText={getErrorMessage("adhar_card")}
                  inputProps={{ accept: "image/jpeg, image/png, application/pdf" }}
                  onChange={uploadUserCertificate}
                />
              </Stack>
              <Stack>
                <Typography fontSize={14} position={"relative"} top={22}>
                  Pan card*
                </Typography>
                <MDInput
                  fullWidth
                  type="file"
                  name="pan_card"
                  margin="normal"
                  variant="filled"
                  className="fileUpload-input"
                  error={isErrorField("pan_card")}
                  helperText={getErrorMessage("pan_card")}
                  inputProps={{ accept: "image/jpeg, image/png, application/pdf" }}
                  onChange={uploadUserCertificate}
                />
              </Stack>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
                sx={{
                  fontSize: {
                    xs: "12px",
                  },
                }}
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <LoaderButton
                btnName={"sign in"}
                fullWidth={true}
                isLoading={isLoadingUserRegistration}
                onClick={formik?.handleSubmit}
              />
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {"Already have an account ? "}
                <MDTypography
                  component={Link}
                  to="/auth/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Log In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
};
