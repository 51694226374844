import { Axios } from "utils";

export const getDashBoard = async (payload) => {
  const res = await Axios?.get("user/dashboard", payload);
  return res.data;
};

export const getAdminDashBoardData = async (payload) => {
  const res = await Axios?.get("admin/dashboard", payload);
  return res.data;
};
