/* eslint-disable react/prop-types */

import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { Stack } from "@mui/material";

export const ProductCard = ({
  isOpen,
  onConfirm,
  isDisabled,
  showBuyNow,
  productDetails,
  onHandleIsOpenModal,
  onHandleProductDetails,
}) => {
  const productCarat = productDetails?.carrot_id === "1" ? "18" : "22";

  return (
    <>
      <Card>
        <img src={productDetails?.product_image} alt="" />
        <CardContent>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Typography gutterBottom fontSize={"1rem"} className="label_font">
              Product Name
            </Typography>
            <Typography gutterBottom fontSize={"1rem"} className="label_font">
              Product Price
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={2} justifyContent={"space-between"}>
            <Typography gutterBottom variant="h5" component="div" fontSize={"1rem"}>
              {productDetails?.name}
            </Typography>
            <Typography gutterBottom variant="h5" component="div" fontSize={"1rem"}>
              {productDetails?.price}
            </Typography>
          </Stack>
          <Typography variant="body2" color="black" className="disc_font">
            Discription
          </Typography>
          <Typography variant="body2" color="text.secondary" className="disc_font">
            {productDetails?.description}
          </Typography>

          <Stack direction={"row"} alignItems={"center"} pt={1} gap={1}>
            <Typography variant="body2" color="black" className="disc_font">
              Carat:
            </Typography>
            <Typography variant="body2" color="text.secondary" className="disc_font">
              {productCarat} c
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} pt={1} gap={1}>
            <Typography variant="body2" color="black" className="disc_font">
              weight(Gm):
            </Typography>
            <Typography variant="body2" color="text.secondary" className="disc_font">
              {productDetails?.weight}
            </Typography>
          </Stack>
        </CardContent>
        <CardActions sx={{ pb: 3 }}>
          <Stack direction={"row"} alignItems={"center"}>
            {showBuyNow && (
              <Button
                disabled={isDisabled}
                sx={{ fontSize: "12px" }}
                onClick={() => onHandleProductDetails({ productDetails })}
              >
                <ShoppingBagIcon sx={{ color: "#2a69d4", width: 20, height: 20 }} />
                Buy Now
              </Button>
            )}
          </Stack>
        </CardActions>
      </Card>
    </>
  );
};
