import React from "react";
import { PendingApproval } from "..";
import { Route, Routes } from "react-router-dom";

export const SalesApprove = () => {
  return (
    <Routes>
      <Route path="/" element={<PendingApproval />} />;
    </Routes>
  );
};
