import React from "react";
import { useQuery } from "react-query";
import { getDistributorList, DISTRIBUTOR_LIST } from "..";

export const useDistributorsQuery = ({ enabled = false }) => {
  const useDistributorListQuery = useQuery([DISTRIBUTOR_LIST], () => getDistributorList(), {
    onSuccess: (data) => {},
    enabled,
    onSettled: () => {},
  });
  const { data: { data } = {} } = useDistributorListQuery || {};

  return {
    query: data,
    isLoading: useDistributorListQuery?.isLoading,
  };
};
