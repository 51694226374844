import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export const CardSection = ({ title, subTitle, discription }) => {
  return (
    <Card
      sx={{
        px: 3,
        height: "292px !important",
        justifyContent: "center",
        border: "1px solid #d8d8d8",
      }}
    >
      <CardContent>
        <Typography fontSize={20} fontWeight={500}>
          {title}
          <br />
          <span> {subTitle}</span>
        </Typography>
        <Typography color={"#8d8888"} fontSize={13}>
          {discription}
        </Typography>
      </CardContent>
    </Card>
  );
};
