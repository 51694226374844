import React from "react";
import { LoaderButton } from "shared";
import { hand_making_3d } from "assets";
import { useAppScopeContext } from "master";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Box, Grid, Stack, Typography, Button, IconButton } from "@mui/material";

export const PaymentModal = ({ isOpen, onClose }) => {
  const { isLoadingPayment, onConfirmPaymentGateway } = useAppScopeContext();

  return (
    <Dialog open={isOpen} maxWidth={"sm"}>
      <Stack py={2} position={"relative"}>
        <IconButton sx={{ position: "absolute", right: "15px" }} onClick={onClose}>
          <CloseIcon sx={{ color: "#636262" }} />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction={"row"} justifyContent={"center"}>
              <Box component={"img"} src={hand_making_3d} alt="payment" sx={{ width: "50%" }} />
            </Stack>
            <Stack px={2}>
              <Typography textAlign={"center"} fontSize={"28px"} fontWeight={500}>
                Make a Payment
              </Typography>
              <Stack mt={2}>
                {/* <Button
                  variant="contained"
                  sx={{ mt: 2, fontWeight: 600, color: "white !important" }}
                  onClick={onConfirmPaymentGateway}
                >
                  Pay Now
                </Button> */}
                <LoaderButton
                  btnName={"Pay Now"}
                  isLoading={isLoadingPayment}
                  onClick={onConfirmPaymentGateway}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Dialog>
  );
};
