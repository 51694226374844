import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import typography from "assets/theme/base/typography";

export const FooterSection = () => {
  const { size } = typography;

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      className="common-footer-section"
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
        className="footer-section-label"
      >
        &copy; {new Date().getFullYear()}
        <MDTypography variant="button" fontWeight="medium">
          &nbsp;{` WebX Tech`}&nbsp;
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};
