/* eslint-disable react/prop-types */

import React from "react";
import dayJs from "dayjs";
import { useCustomer } from "..";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { ReactTable, DeleteModal } from "shared";
import { Stack, Grid, Card } from "@mui/material";
import { useMaterialUIController } from "context";
import { handleNavigation, trimText } from "utils";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

export const CustomerList = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const route = [{ name: "Customer" }];

  const { useCustomerListQuery } = useCustomer({ enabled: true });

  const { data: { data: customerData } = {} } = useCustomerListQuery || {};

  const ActionButton = ({ row }) => {
    const { id, name } = row.original;

    return (
      <Stack direction={"row"} alignItems={"center"} gap={1} key={`${id}_ActionButton`}>
        <MDButton
          variant="text"
          color={darkMode ? "white" : "dark"}
          sx={{ padding: "4px !important" }}
          onClick={() => {}}
        >
          <Icon>edit</Icon>
        </MDButton>
      </Stack>
    );
  };

  const columns = [
    {
      Header: "Name",
      accessor: "username",
    },
    {
      Header: "Phone Number",
      accessor: "phone_no",
    },
    {
      Header: "Created Date",
      accessor: "created_at",
      Cell: ({ row }) => {
        const { created_at } = row.original;

        const deliveryDate = dayJs(created_at)?.format("DD/MM/YYYY");

        return deliveryDate;
      },
    },
    {
      Header: "Aadhaar/Pan",
      accessor: "adhar_card",
      Cell: ({ row }) => {
        return (
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <a href={row?.original?.adhar_card} target="_blank">
              aadhaar
            </a>
            <span>/</span>
            <a href={row?.original?.pan_card} target="_blank">
              pan
            </a>
          </Stack>
        );
      },
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbRoute={route} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <MDTypography variant="h6" color="white">
                    Customer List
                  </MDTypography>
                </Stack>
              </MDBox>
              <MDBox pt={3}>
                <ReactTable
                  noEndBorder
                  isSorted={true}
                  canSearch={true}
                  showTotalEntries={true}
                  entriesPerPage={{ defaultValue: 15 }}
                  table={{ columns, data: customerData ? customerData : [] }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <DeleteModal
        text={"Distributor name"}
        itemName={deleteItem?.name}
        isOpen={deleteItem?.name}
        onConfirm={onHandelDeleteDistributor}
        handleClose={resetDeleteData}
      /> */}
    </DashboardLayout>
  );
};
