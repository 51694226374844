import { Axios } from "utils";

export const getAreaListData = async () => {
  const res = await Axios.get(`admin/area`);
  return res.data;
};

export const getAreaListById = async (areaId) => {
  const res = await Axios.get(`admin/area/${areaId}`);
  return res.data;
};

export const createAreas = async (payload) => {
  const res = await Axios.post(`admin/area`, payload);
  return res.data;
};

export const updateAreaById = async (payload) => {
  const { id, ...rest } = payload;
  console.log("rest: ", rest);
  const res = await Axios.post(`admin/area/${id}`, rest);
  return res.data;
};

export const deleteAreas = async (areaId) => {
  const res = await Axios.delete(`admin/area/${areaId}`);
  return res.data;
};
