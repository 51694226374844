import { useState, useEffect, useMemo } from "react";
import routes from "routes";
import theme from "assets/theme";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import { LandingPage } from "modules";
import Sidenav from "examples/Sidenav";
import createCache from "@emotion/cache";
import themeDark from "assets/theme-dark";
import rtlPlugin from "stylis-plugin-rtl";
import themeRTL from "assets/theme/theme-rtl";
import { MainRoutes, AppScope } from "master";
import { CacheProvider } from "@emotion/react";
import Configurator from "examples/Configurator";
import brandWhite from "assets/images/logo-ct.png";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import brandDark from "assets/images/logo-ct-dark.png";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import {
  Login,
  SignUp,
  SideNevBar,
  FooterSection,
  ProtectedRoute,
  NevBarLandingPage,
} from "shared";

export const App = () => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    layout,
    darkMode,
    direction,
    miniSidenav,
    sidenavColor,
    whiteSidenav,
    openConfigurator,
    transparentSidenav,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route?.route} element={route?.component} key={route?.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName=""
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <SideNevBar
            brandName=""
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/* <Configurator /> */}
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <AppScope>
        <Routes>
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <MainRoutes />
                <FooterSection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <>
                <NevBarLandingPage />
                <LandingPage />
              </>
            }
          />
          <Route path="/auth/login" element={<Login />} />
          <Route
            path="/auth/register"
            element={
              <>
                <NevBarLandingPage />
                <SignUp />
              </>
            }
          />
        </Routes>
      </AppScope>
    </ThemeProvider>
  );
};
