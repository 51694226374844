import React, { useEffect, useRef } from "react";

export const AutoPlayComponents = ({ source, width = "100%" }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          videoRef.current.play().catch((error) => console.error(error));
        } else {
          videoRef.current.pause();
        }
      },
      {
        threshold: 0.2,
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <video
      loop
      autoPlay
      playsInline
      width={width}
      muted={true}
      height="auto"
      ref={videoRef}
      style={{ boxShadow: "0 7px 29px 0 #64646f33" }}
      className="ng-tns-c109-1 ng-star-inserted product-animation"
    >
      <source type="video/mp4" className="ng-tns-c109-1" src={source} />
    </video>
  );
};
