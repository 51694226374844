import axios from "axios";
import { getToken, hasRemoveUserData } from ".";

export const Axios = axios.create({
  // baseURL: "http://127.0.0.1:8000/" + "api/",
  baseURL: process.env.REACT_APP_API_URL + "api/",
  headers: {
    "Content-Type": "application/json",
  },
});

Axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    } else {
      config.headers["Authorization"] = null;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (res) => {
    if (res?.config?.url !== "/login") {
      if (res?.response?.status === 401 || res?.response?.data === "Unauthorized.") {
        hasRemoveUserData();
        window.location.href = "/auth/login";
      }
    }
    return res;
  },
  async (error) => {
    if (error?.config?.url !== "/login") {
      if (error?.response?.status === 401 || error?.response?.data === "Unauthorized.") {
        hasRemoveUserData();
        window.location.href = "/auth/login";
      }
    }
    return Promise.reject(error);
  }
);
