import React from "react";
import { trimText } from "utils";
import { useRegistration } from "..";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Stack, Grid, Card, Button } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { ReactTable, Loader, ConfirmModal, StatusBtn } from "shared";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

export const RegistrationList = () => {
  const {
    status,
    useRegisterListQuery,
    isOpenRegistrationModal,
    setStatus,
    setIsOpenRegistrationModal,
    onHandleRegistrationStatusUpdate,
  } = useRegistration({
    enabled: true,
  });

  const { id } = isOpenRegistrationModal;
  const { data: { data: registrationList } = {}, isLoading } = useRegisterListQuery || {};

  const route = [{ name: "Registration List" }];

  const columns = [
    {
      Header: "User Name",
      accessor: "username",
      Cell: ({ row }) => {
        return trimText(row?.original?.username);
      },
    },
    {
      Header: "Phone Number",
      accessor: "phone_no",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Aadhaar/Pan",
      accessor: "adhar_card",
      Cell: ({ row }) => {
        return (
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <a href={row?.original?.adhar_card} target="_blank">
              aadhaar
            </a>
            <span>/</span>
            <a href={row?.original?.pan_card} target="_blank">
              pan
            </a>
          </Stack>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (value) => (
        <StatusBtn
          value={value}
          onClick={(id) => {
            setIsOpenRegistrationModal((draft) => {
              draft.id = id;
              draft.isOpen = true;
            });
          }}
        />
      ),
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbRoute={route} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <MDTypography variant="h6" color="white">
                    Registration List
                  </MDTypography>
                </Stack>
              </MDBox>
              <Stack className="table-head-btn" px={2}>
                <Button
                  className={status === "pending" ? "active d1" : "inactive"}
                  onClick={() => {
                    setStatus("pending");
                  }}
                >
                  Pending
                </Button>
                <Button
                  className={status === "approved" ? "active d1" : "inactive"}
                  onClick={() => {
                    setStatus("approved");
                  }}
                >
                  Completed
                </Button>
              </Stack>
              <MDBox pt={3}>
                <ReactTable
                  noEndBorder
                  isSorted={true}
                  canSearch={true}
                  showTotalEntries={true}
                  entriesPerPage={{ defaultValue: 15 }}
                  table={{ columns, data: registrationList ? registrationList : [] }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ConfirmModal
        title={"Confirm"}
        isOpen={isOpenRegistrationModal?.isOpen}
        onClose={() => setIsOpenRegistrationModal(false)}
        onConfirm={() => onHandleRegistrationStatusUpdate({ id })}
        subTittle={" Are you sure you want to approve this user registration?"}
      />
    </DashboardLayout>
  );
};
