import React from "react";
import MDBox from "components/MDBox";
import { HeaderSection, Loader } from "shared";
import { useCreateCategory } from "..";
import MDButton from "components/MDButton";
import { trimText, handleNavigation } from "utils";
import { useParams, useNavigate } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Button, Card, Grid, Stack, TextField, Typography } from "@mui/material";

export const EditOrCreateCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    currentCategory,
    selectedCategory,
    onChangeCategoryName,
    isLoadingCategoryDetail,
    onHandleCreateCategory,
  } = useCreateCategory(id);

  const route = [
    { navLink: "categoryList", name: "CategoryList" },
    { name: id ? trimText(currentCategory) : "Create Item" },
  ];

  if (isLoadingCategoryDetail) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbRoute={route} />
      <Card sx={{ p: 3 }}>
        <HeaderSection currentName={id ? currentCategory : "Create Category"} />
        <MDBox pt={6} pb={3}>
          <Typography className="label_font" fontWeight={500}>
            Category*
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Stack mb={2}>
                <TextField value={selectedCategory?.category} onChange={onChangeCategoryName} />
              </Stack>
            </Grid>
          </Grid>
          <Stack position={"relative"} className="item_moduel-footer">
            <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} gap={2}>
              <MDButton
                variant="gradient"
                color="light"
                onClick={() => handleNavigation("..", navigate)}
              >
                &nbsp;Cancel
              </MDButton>
              <MDButton variant="gradient" color="info" onClick={onHandleCreateCategory}>
                &nbsp;{id ? "update" : "Create"}
              </MDButton>
            </Stack>
          </Stack>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
};
