import React from "react";
import { policyDetails } from "..";
import { CardSection } from "shared";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { BottomBanner, BottomBanner2 } from "assets";

export const CardDetailSection = () => {
  return (
    <Stack mt={5}>
      <Stack alignItems={"center"} mb={2}>
        <Typography fontSize={38}>Maizon Promises</Typography>
        <Typography fontSize={23}> The promises that we'll never break</Typography>
      </Stack>
      <Grid container spacing={2}>
        {policyDetails?.map((policyData) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CardSection
                title={policyData?.title}
                subTitle={policyData?.subTitle}
                discription={policyData?.discription}
              />
            </Grid>
          );
        })}
      </Grid>
      <Stack mt={1}>
        <Box
          component={"img"}
          src={BottomBanner}
          sx={{ display: { xs: "none", md: "block" }, borderRadius: "10px !important" }}
        />
        <Box sx={{ display: { sm: "block", md: "none" } }} component={"img"} src={BottomBanner2} />
      </Stack>
    </Stack>
  );
};
