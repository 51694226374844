/* eslint-disable react/prop-types */

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";

export const DeleteModal = ({ isOpen, handleClose, onConfirm, text = "", itemName = "" }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: darkMode ? "#202940" : "",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure? Do you want to delete ${text} ${itemName?.toUpperCase()}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="info"
            sx={{ padding: "4px !important" }}
            onClick={handleClose}
          >
            Cancel
          </MDButton>
          <MDButton variant="gradient" color="error" onClick={onConfirm}>
            ok
          </MDButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
