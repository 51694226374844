import { Grid, Stack, Typography, List, ListItem } from "@mui/material";
import React from "react";
import { PopoverComponent } from "shared";

export const FooterComponent = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack sx={{ boxShadow: "1px 5px 15px -12px rgba(0, 0, 0, 0.75)" }} p={3} borderRadius={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Stack mb={2}>
            <Typography fontSize={"13px"} fontWeight={500} mb={2}>
              Contacts
            </Typography>
            <Typography fontSize={"13px"}>
              <strong>Address :</strong> Maizon Enterprise , Near GHSS Koppam, Cherpulassery Rd,
              Koppam, Palakkad – Kerala, 679307
            </Typography>
          </Stack>
          <Stack mb={2}>
            <Typography fontSize={"13px"}>
              <strong>Phone Number : </strong>
              +91 8075 660 916
            </Typography>
          </Stack>
          <Stack mb={2}>
            <Typography fontSize={"13px"} fontWeight={500}>
              <strong>Email ID : </strong>
              support@maizon.gold
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Stack>
            <Typography fontSize={"13px"} fontWeight={500} mb={2}>
              Terms & Conditions
            </Typography>
            <Typography
              fontSize={"13px"}
              lineHeight={2}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <strong>Introduction</strong>
              Welcome to Maizon Gold! These terms and conditions outline the rules and regulations
              for the use of Maizon Gold's Website, located
              <a> www.maizon.gold</a> By accessing this website we assume you accept these terms and
              conditions. Do not continue to use Maizon Gold if you do not agree to all of the terms
              and conditions stated on this page.
            </Typography>
            {/* <PopoverComponent anchorEl={anchorEl} description={``} /> */}
            <Typography fontSize={"13px"} lineHeight={2} mt={1}>
              <strong>Registration</strong> To register as a user, you must provide accurate and
              complete information and keep your account information updated. Each registration is
              for a single user only.
            </Typography>
            <Typography fontSize={"13px"} lineHeight={2} mt={1}>
              <strong>Deposits</strong> Customers must deposit a minimum of 100 rupees to start
              purchasing from our range of gold jewelry.
            </Typography>
            <Typography fontSize={"13px"} lineHeight={2} mt={1}>
              <strong> Use of Website</strong> You must not:
              <Typography fontSize={"13px"} lineHeight={2}>
                <List>
                  <ListItem>• Republish material from www.maizon.gold</ListItem>
                  <ListItem>• Sell, rent, or sub-license material from www.maizon.gold</ListItem>
                  <ListItem>• Reproduce, duplicate, or copy material from www.maizon.gold</ListItem>
                </List>
              </Typography>
            </Typography>
            <Stack>
              <Typography fontSize={"15px"} fontWeight={500} mt={2}>
                Shipping Policy
              </Typography>
              <Typography fontSize={"13px"} lineHeight={2} mt={1}>
                At Maizon.Gold, we strive to provide you with a seamless shopping experience from
                order to delivery. We ship all orders through trusted courier partner and Shipping
                is FREE OF COST across all of India, All orders are processed within 3-5 business
                days (excluding weekends and holidays) after receiving your order confirmation. At
                delivery, the recipient must show a government-issued ID (Passport, PAN Card,
                Driver's License, Voter's ID, Aadhar Card).
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Typography fontSize={"13px"} fontWeight={500} mb={2}>
            Privacy Policy
          </Typography>
          <Typography fontSize={"13px"} lineHeight={2}>
            <strong>Introduction </strong>
            At Maizon Gold, accessible from www.maizon.gold, one of our main priorities is the
            privacy of our visitors. This Privacy Policy document contains types of information that
            is collected and recorded by <strong>Maizon Gold </strong> and how we use it.
          </Typography>

          <Stack mt={2}>
            <Typography fontSize={"13px"} fontWeight={500} mb={2}>
              Refund Policy
            </Typography>

            <Typography fontSize={"13px"} lineHeight={2}>
              <strong>Products and Services Covered</strong> Our refund policy applies to all
              products and services available on our website. Whether you have purchased physical
              goods, digital products, or services through our software-added website, you are
              entitled to a refund as outlined below.
            </Typography>
          </Stack>

          <Stack mt={2}>
            <Typography fontSize={"13px"} fontWeight={500} mb={2}>
              Refund Process
            </Typography>

            <Typography fontSize={"13px"} lineHeight={2}>
              <strong>1. Initiate a Refund Request: </strong> o To request a refund, please log in
              to your account on maizon.gold and navigate to the "Order History" section. o Select
              the order you wish to refund and click on the "Request Refund" button. o Provide the
              reason for the refund and any relevant details to help us process your request
              efficiently.
              <br /> <strong>2. Eligibility for Refund:</strong> Products must be in their original
              condition and packaging to qualify for a refund. For services, the refund will be
              evaluated based on the progress and deliverables provided up to the point of the
              refund request.
              <br /> <strong>3. Processing Time:</strong> Once your refund request is received and
              approved, we will process your refund within 5-7 working days. The credited amount
              will be returned to the original payment method used during the purchase. <br />
              <strong> 4. Exceptions: </strong>
              Custom or personalized products are non-refundable unless they arrive damaged or
              defective. Certain services may have specific refund terms, which will be communicated
              at the time of purchase.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}></Grid>
      </Grid>
    </Stack>
  );
};
