import React from "react";
import { useImmer } from "use-immer";

const initData = {
  id: "",
  name: "",
  from: "",
};

export const useDelete = () => {
  const [deleteItem, setDeleteItem] = useImmer(initData);

  const resetDeleteData = () => {
    setDeleteItem(initData);
  };

  const onHandleDeleteItemDetails = ({ id, name, from }) => {
    setDeleteItem((draft) => {
      draft.id = id;
      draft.name = name;
      draft.from = from;
      return draft;
    });
  };

  return {
    deleteItem,
    resetDeleteData,
    onHandleDeleteItemDetails,
  };
};
