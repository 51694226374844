import React from "react";
import { ProductList } from ".";

export const ProductPage = () => {
  return (
    <div>
      <ProductList />
    </div>
  );
};
