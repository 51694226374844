import { Axios } from "utils";

export const getNotificationList = async () => {
  const res = await Axios.get("admin/notification");
  return res.data;
};

export const getNotificationById = async (id) => {
  console.log("id: ", id);
  const res = await Axios.get(`admin/notification/${id}`);
  return res.data;
};

export const createNotification = async (payload) => {
  const res = await Axios.post(`admin/notification`, payload);
  return res.data;
};

export const updateNotification = async (payload) => {
  const { id, ...rest } = payload;
  const res = await Axios.put(`admin/notification/${id}`, rest);
  return res.data;
};

export const deleteNotification = async (id) => {
  const res = await Axios.delete(`admin/notification/${id}`);
  return res.data;
};
