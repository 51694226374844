import { useEffect } from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useLocation, NavLink, Link } from "react-router-dom";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import {
  setMiniSidenav,
  setWhiteSidenav,
  setTransparentSidenav,
  useMaterialUIController,
} from "context";
import { isActiveSidenav, getPaymentStatus } from "utils";
import { useAuthenticate } from "master";

export const SideNevBar = ({ color, brand, brandName, routes, ...rest }) => {
  const location = useLocation();
  const isDisabled = getPaymentStatus();

  // Ensure hooks are not called conditionally
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const collapseName = location.pathname.replace("/", "");

  const { hasUserOrAdminPermission } = useAuthenticate() || {};
  const isAdminOrUser = hasUserOrAdminPermission();

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    // The event listener that calls the handleMiniSidenav function when resizing the window.
    window.addEventListener("resize", handleMiniSidenav);
    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, transparentSidenav, whiteSidenav]);

  if (location?.pathname === "/") {
    return null;
  }

  let textColor = "white";
  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const renderRoutes = routes?.map(
    ({ type, name, icon, title, noCollapse, key, href, route, pathName, isVisible }) => {
      let returnValue;
      if (type === "collapse") {
        if (key === "dashboard") {
          returnValue = (
            <NavLink key={key} to={pathName}>
              <SidenavCollapse
                name={name}
                icon={icon}
                active={isActiveSidenav({ location, activeKey: key })}
              />
            </NavLink>
          );
        }
        if (isAdminOrUser === "admin" && isVisible === "admin") {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              />
            </Link>
          ) : (
            <NavLink key={key} to={pathName}>
              <SidenavCollapse
                name={name}
                icon={icon}
                active={isActiveSidenav({ location, activeKey: key })}
              />
            </NavLink>
          );
        } else if (isVisible === "user" && isAdminOrUser === "user") {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              />
            </Link>
          ) : (
            <>
              {isDisabled ? (
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  isDisabled={isDisabled}
                  active={isActiveSidenav({ location, activeKey: key })}
                />
              ) : (
                <NavLink key={key} to={pathName}>
                  <SidenavCollapse
                    name={name}
                    icon={icon}
                    active={isActiveSidenav({ location, activeKey: key })}
                  />
                </NavLink>
              )}
            </>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }
      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={2}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <List>{renderRoutes}</List>
      <MDBox p={2} mt="auto"></MDBox>
    </SidenavRoot>
  );
};

SideNevBar.defaultProps = {
  color: "info",
  brand: "",
};

SideNevBar.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};
