import { useImmer } from "use-immer";
import { getProductSales, approvePendingSalesStatus } from "..";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { showErrorMessage, showSuccessPopUp } from "utils";

export const useSalesList = ({ enabled = false }) => {
  const queryClient = useQueryClient();

  const [salesStatus, setSalesStatus] = useImmer("");

  const useSalesListQuery = useQuery(
    ["SALES_LIST", salesStatus],
    () => getProductSales({ salesStatus }),
    {
      enabled: Boolean(enabled && salesStatus),
      staleTime: 0,
      onSuccess: (data) => {},
      onSettled: () => {},
    }
  );

  const approveSalesStatus = useMutation(approvePendingSalesStatus, {
    onSuccess: () => {
      queryClient?.invalidateQueries(["SALES_LIST"]);
      showSuccessPopUp({ text: "Sales Status updated", title: "Approved" });
    },
    onError: (data) => {
      console.log("data: ");
      showErrorMessage({ message: data?.response?.data?.message });
    },
  });

  const onHandleApproveSalesOrder = ({ id }) => {
    approveSalesStatus?.mutate({ id });
  };

  return { useSalesListQuery, setSalesStatus, onHandleApproveSalesOrder };
};
