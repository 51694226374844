import React from "react";
import { NumericFormat } from "react-number-format";

export const InputControl = React.forwardRef((props, ref) => {
  const { value, prefix = "", suffix = "", displayType, className, ...rest } = props;

  return (
    <NumericFormat
      {...rest}
      value={value}
      thousandSeparator
      allowLeadingZeros
      prefix={prefix + " "}
      suffix={" " + suffix}
      displayType={displayType}
      thousandsGroupStyle="lakh"
      className={className}
      getInputRef={ref}
    />
  );
});
