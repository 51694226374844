import { Axios } from "utils";

export const getCategoryList = async () => {
  const res = await Axios.get(`admin/category`);
  return res.data;
};

export const getCategoryById = async (id) => {
  const res = await Axios.get(`/admin/category/${id}`);
  return res.data;
};

export const createCategory = async (payload) => {
  const { id, ...rest } = payload;
  const data = id ? rest : payload;
  const url = id ? `/admin/category/${id}` : `/admin/category`;

  const res = await Axios.post(url, data);
  return res.data;
};

export const deleteCategory = async (id) => {
  const res = await Axios.delete(`/admin/category/${id}`);
  return res.data;
};
