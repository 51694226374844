import React from "react";
import { imageArray } from "..";
import { Banner3, Banner4, BannerNew1, BANNERNew2 } from "assets";
import { Grid, Stack } from "@mui/material";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";

export const BannerSectionOne = () => {
  return (
    <Grid container spacing={2} sx={{ rowGap: 2 }}>
      <Grid item xs={12} md={12}>
        <Stack>
          <Carousel
            autoPlay={true}
            showArrows={true}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            stopOnHover={false}
            showIndicators={false}
            interval={2000}
          >
            <img src={BannerNew1} alt="images" />
            <img src={Banner3} alt="images" />
            <img src={Banner4} alt="images" />
            <img src={BANNERNew2} alt="images" />
          </Carousel>
        </Stack>
      </Grid>
    </Grid>
  );
};
