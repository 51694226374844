import React from "react";
import { CreateOrUpdateArea, AreaList } from ".";
import { Routes, Route } from "react-router-dom";

export const Area = () => {
  return (
    <Routes>
      <Route path={"/"} element={<AreaList />} />
      <Route path={"/area/edit/:id"} element={<CreateOrUpdateArea />} />
      <Route path={"/area/create"} element={<CreateOrUpdateArea />} />
    </Routes>
  );
};
