import React from "react";
import { DistributorsList, CreateOrUpdateDistributors } from ".";
import { Routes, Route } from "react-router-dom";

export const Distributors = () => {
  return (
    <Routes>
      <Route path={"/"} element={<DistributorsList />} />
      <Route path={"/distributor/create"} element={<CreateOrUpdateDistributors />} />
      <Route path={"/distributor/edit/:id"} element={<CreateOrUpdateDistributors />} />
    </Routes>
  );
};
