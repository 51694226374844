import { useImmer } from "use-immer";
import { getPaymentStatus } from "utils";
import {
  logOutUser,
  performLogin,
  paymentCreation,
  userRegistration,
  checkUserPaymentStatus,
} from "..";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  successMessage,
  showErrorMessage,
  showWarningMessage,
  onAlertPurchaseDetails,
} from "utils";
import { useEffect } from "react";

const initLoginCredential = {
  email: "",
  password: "",
};

const initregistrationCredential = {
  email: "",
  pan_card: "",
  password: "",
  username: "",
  phone_no: "",
  address: "",
  adhar_card: "",
};

export const useAuthenticate = () => {
  const navigate = useNavigate();
  const hasNotpaymentDone = getPaymentStatus();
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useImmer(true);
  const [isCheckPaymentStatus, setIsCheckPaymentStatus] = useImmer(false);
  const [loginCredential, setUserCredential] = useImmer(initLoginCredential);
  const [registrationCredential, setRegistrationCredential] = useImmer(initregistrationCredential);

  const hasUserOrAdminPermission = () => {
    const localUserData = JSON.parse(localStorage.getItem("userData"));
    if (localUserData) {
      const { is_super_admin } = localUserData;
      return is_super_admin ? "admin" : "user";
    }
    return "permission denied";
  };

  const { error, refetch } = useQuery(["adminDashBoardData"], checkUserPaymentStatus, {
    refetchIntervalInBackground: false,
    refetchInterval: isCheckPaymentStatus ? 60000 : false,
    enabled: false,
    refetchOnWindowFocus: true,
    enabled: hasUserOrAdminPermission() === "user",
    onSuccess: (data) => {
      localStorage?.setItem("paymentStatus", JSON?.stringify(data));
      setIsOpenPaymentModal(hasNotpaymentDone);
      setIsCheckPaymentStatus(hasNotpaymentDone);
    },
    onError: () => {
      setIsCheckPaymentStatus(false);
    },
  });

  useEffect(() => {
    if (hasUserOrAdminPermission() === "user") {
      if (hasNotpaymentDone) {
        setIsCheckPaymentStatus(true);
      } else {
        if (error) {
          setIsCheckPaymentStatus(false);
        } else {
          refetch();
        }
      }
    }
  }, []);

  const paymentMutation = useMutation(paymentCreation, {
    onSuccess: (data) => {
      const { data: paymetGateWayData } = data || {};
      if (paymetGateWayData) {
        const paymentObject = new window.Razorpay(paymetGateWayData);
        paymentObject.open();
        setIsOpenPaymentModal(false);
      }
    },
  });

  const userRgisteration = useMutation(userRegistration, {
    onSuccess: (data) => {
      const userData = {
        ...data?.user,
        token: data?.access_token,
      };

      onAlertPurchaseDetails({
        message: "Admin will confirm your registration",
        navigate: () => navigate("/dashboard", { replace: true }),
      });
    },
    onError: (data) => {
      // showErrorMessage({ message: "Registration failed. Please try again. " });
      showWarningMessage({ message: "File exceeds size limit. Please upload a smaller file" });
    },
  });

  const loginUser = useMutation(performLogin, {
    onSuccess: (data) => {
      const userData = {
        ...data?.user,
        token: data?.access_token,
      };
      localStorage.setItem("userData", JSON.stringify(userData));
      navigate("/dashboard", { replace: true });
      successMessage({ message: "Successfully logged in" });
      setIsOpenPaymentModal(true);
    },
    onError: (res) => {
      showErrorMessage({ message: res?.response?.data?.message });
    },
  });

  const userLogOut = useMutation(logOutUser, {
    onSuccess: () => {
      localStorage.removeItem("userData");
      navigate("auth/login", { replace: true });
    },
  });

  const onConfirmPaymentGateway = () => {
    paymentMutation?.mutate();
  };

  const uploadUserCertificate = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    setRegistrationCredential((draft) => {
      draft[name] = file;
      return draft;
    });
  };

  const onHandleUserLogin = (user) => {
    loginUser.mutate(loginCredential);
  };

  const onHandleUserLogOut = () => {
    userLogOut.mutate();
  };

  const onHandleUserRegistration = () => {
    userRgisteration.mutate(registrationCredential);
  };

  const onChangeUserCredential = (e) => {
    const { name, value } = e.target;

    setUserCredential((draft) => {
      draft[name] = value;
    });
  };

  const onChangeUserRegistration = (e) => {
    const { value, name } = e?.target;

    setRegistrationCredential((draft) => {
      draft[name] = value;
    });
  };

  const getAuthToken = () => {
    const localUserData = JSON.parse(localStorage.getItem("userData"));
    const token = localUserData?.token;
    return token;
  };

  const isLoadingUserLogin = loginUser?.isLoading;
  const isLoadingPayment = paymentMutation?.isLoading;
  const isLoadingUserRegistration = userRgisteration?.isLoading;

  return {
    loginCredential,
    isLoadingPayment,
    isOpenPaymentModal,
    isLoadingUserLogin,
    registrationCredential,
    isLoadingUserRegistration,
    getAuthToken,
    onHandleUserLogin,
    onHandleUserLogOut,
    setIsOpenPaymentModal,
    uploadUserCertificate,
    onChangeUserCredential,
    onConfirmPaymentGateway,
    onHandleUserRegistration,
    onChangeUserRegistration,
    hasUserOrAdminPermission,
  };
};
