/* eslint-disable react/prop-types */

import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { Stack, Grid, Card } from "@mui/material";
import { useMaterialUIController } from "context";
import { handleNavigation, trimText } from "utils";
import MDTypography from "components/MDTypography";
import { ReactTable, DeleteModal, Loader } from "shared";
import { useProductScopeContext, useItemListQuery } from "..";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

export const ItemList = () => {
  const navigate = useNavigate();

  const route = [{ name: "Items" }];
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { setEnabled, setProductId, deleteItemName, onHandleDeleteItem, onHandleDeleteItemModal } =
    useProductScopeContext();
  const { query: productList, isLoading } = useItemListQuery({ enabled: true });

  useEffect(() => {
    setEnabled(true);
    setProductId(null);
    return () => {
      setEnabled(false);
    };
  }, []);

  const ProductName = ({ row }) => {
    const { name } = row.original;
    return <span>{trimText(name)}</span>;
  };

  const ActionButton = ({ row }) => {
    const { id, name } = row.original;

    return (
      <Stack direction={"row"} alignItems={"center"} gap={1} key={`${id}_ActionButton`}>
        <MDButton
          variant="text"
          color={darkMode ? "white" : "dark"}
          sx={{ padding: "4px !important" }}
          onClick={() => {
            handleNavigation(`./itemDetail/edit/${id}`, navigate);
          }}
        >
          <Icon>edit</Icon>
        </MDButton>
        <MDButton
          variant="text"
          color="error"
          onClick={() => onHandleDeleteItemModal({ itemName: name, id })}
        >
          <Icon>delete</Icon>
        </MDButton>
      </Stack>
    );
  };

  const columns = [
    {
      Header: "Product Name",
      accessor: "name",
      Cell: ProductName,
    },
    {
      Header: "Caret",
      accessor: "caret",
    },
    {
      Header: "Weight",
      accessor: "Weight",
    },
    {
      Header: "price",
      accessor: "price",
    },
    {
      Header: "Action",
      align: "center",
      accessor: "id",
      isNotVisibleSort: true,
      Cell: ActionButton,
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbRoute={route} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <MDTypography variant="h6" color="white">
                    Item List
                  </MDTypography>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={() => handleNavigation("./itemDetail/create", navigate)}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;Create
                  </MDButton>
                </Stack>
              </MDBox>
              <MDBox pt={3}>
                <ReactTable
                  noEndBorder
                  isSorted={true}
                  canSearch={true}
                  showTotalEntries={true}
                  entriesPerPage={{ defaultValue: 15 }}
                  table={{ columns, data: productList ? productList : [] }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <DeleteModal
        text={"Item name"}
        itemName={deleteItemName?.name}
        isOpen={deleteItemName?.name}
        onConfirm={onHandleDeleteItem}
        handleClose={() => onHandleDeleteItemModal({ itemName: "", id: null })}
      />
    </DashboardLayout>
  );
};
