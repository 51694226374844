import { Axios } from "utils";

export const performLogin = async (payload) => {
  const res = await Axios?.post("/login", payload);
  return res.data;
};

export const logOutUser = async () => {
  const res = await Axios?.post("/logout");
  return res.data;
};

export const getCurretItemCategorPrize = async () => {
  const res = await Axios?.get("admin/carrot-settings");
  return res.data;
};

export const checkUserPaymentStatus = async () => {
  const res = await Axios?.get("user/payment-status");
  return res.data;
};

export const paymentCreation = async () => {
  const res = await Axios?.get("user/make-payment");
  return res.data;
};

export const updateItemCategoryPrices = async (payload) => {
  const res = await Axios?.post("/admin/carrot-settings", payload);
  return res.data;
};

export const userRegistration = async (payload) => {
  const res = await Axios?.post("/register", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};
