import { useEffect } from "react";
import { useDelete } from "shared";
import { useImmer } from "use-immer";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  getAreaList,
  DISTRIBUTOR_LIST,
  createDistributors,
  updateDistributors,
  deleteDistributors,
  getDistributorList,
  getDistributorById,
} from "..";

const initDistributor = { name: "", area_id: "" };

export const useDistributor = ({ enabled = false, id = null }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { deleteItem, onHandleDeleteItemDetails, resetDeleteData } = useDelete();

  const [currentDistributor, setCurrentDistributor] = useImmer("");
  const [selectedDistributor, setSelectedDistributor] = useImmer(initDistributor);

  const useDistributorDetail = useQuery([DISTRIBUTOR_LIST, id], () => getDistributorById(id), {
    onSuccess: (data) => {},
    enabled: !!id,
    onSettled: () => {},
  });

  const useAreaList = useQuery(["areaList"], () => getAreaList(), {
    enabled: true,
    staleTime: 0,
  });

  useEffect(() => {
    if (useDistributorDetail.data) {
      const { data } = useDistributorDetail?.data || {};
      setSelectedDistributor((draft) => {
        draft.name = data?.name;
        draft.area_id = data?.area?.id;
        return draft;
      });
      setCurrentDistributor((draft) => {
        draft = data?.name;
        return draft;
      });
    }
  }, [useDistributorDetail.data]);

  const createDistributor = useMutation(createDistributors, {
    onSuccess: () => {
      queryClient?.invalidateQueries([DISTRIBUTOR_LIST]);
      navigate("..", { replace: true });
    },
  });

  const updateDistributor = useMutation(updateDistributors, {
    onSuccess: () => {
      queryClient?.invalidateQueries([DISTRIBUTOR_LIST]);
      navigate("..", { replace: true });
    },
  });

  const deleteDistributor = useMutation(deleteDistributors, {
    onSuccess: () => {
      queryClient?.invalidateQueries([DISTRIBUTOR_LIST]);
    },
    onSettled: () => {
      resetDeleteData();
    },
  });

  const onHandelDeleteDistributor = () => {
    deleteDistributor?.mutate(deleteItem?.id);
  };

  const onHandleCreateOrUpdateDistributor = () => {
    if (id) {
      updateDistributor?.mutate({ ...selectedDistributor, _method: "PUT", active: 0, id });
    } else {
      createDistributor?.mutate(selectedDistributor);
    }
  };

  const onChangeDistributorDetail = (event) => {
    const { name, value } = event?.target;
    setSelectedDistributor((draft) => {
      draft[name] = value;
      return draft;
    });
  };

  return {
    useAreaList,
    deleteItem,
    currentDistributor,
    selectedDistributor,
    resetDeleteData,
    onHandleDeleteItemDetails,
    onChangeDistributorDetail,
    onHandelDeleteDistributor,
    onHandleCreateOrUpdateDistributor,
  };
};
