import React from "react";
import { sectionImages } from "..";
import { Grid, Stack, Box, Typography } from "@mui/material";

export const CategorySection = () => {
  return (
    <div>
      <Stack>
        <Grid container spacing={2}>
          {sectionImages?.map((imageData, index) => {
            return (
              <Grid item xs={6} md={3} lg={2} key={`${index}_item-row`}>
                <Stack overflow={"hidden"}>
                  <Stack
                    sx={{
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                      transition: "transform 0.3s ease-in-out",
                    }}
                  >
                    <Stack className="product-section">
                      <Box component="img" src={imageData?.image} sx={{ borderRadius: 2 }} />
                    </Stack>
                    <Stack mt={"30px"}>
                      <Stack textAlign={"center"} px={3}>
                        <Typography fontSize={12}>{imageData?.discription}</Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack mt={"30px"}>
                    <Stack textAlign={"center"}>
                      <Typography fontSize={12} fontWeight={500}>
                        {imageData?.type}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </div>
  );
};
