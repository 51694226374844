// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";
import { Stack } from "@mui/material";

function DataTableHeadCell({ width, isAction, children, sorted, align, isShowSortIcon, ...rest }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox
      px={3}
      py={1.5}
      component="th"
      width={isAction ? "20%" : width}
      sx={({ palette: { light }, borders: { borderWidth } }) => ({
        borderBottom: `${borderWidth[1]} solid ${light.main}`,
      })}
    >
      <MDBox
        {...rest}
        opacity={0.7}
        textAlign={align}
        position="relative"
        className="react-table-heade_cell"
        color={darkMode ? "white" : "secondary"}
        sx={({ typography: { size, fontWeightBold } }) => ({
          fontSize: size.xxs,
          fontWeight: fontWeightBold,
          textTransform: "uppercase",
          cursor: sorted && "pointer",
          userSelect: sorted && "none",
        })}
      >
        <Stack direction={"row"} alignItems={"center"} justifyContent={isAction && "center"}>
          <span style={{ fontSize: "14px", fontWeight: "500" }}>{children}</span>
          {sorted && !isShowSortIcon && (
            <MDBox
              top={0}
              right={align !== "right" ? "16px" : 0}
              left={align === "right" ? "-5px" : "unset"}
              sx={({ typography: { size } }) => ({
                fontSize: size.lg,
              })}
            >
              <MDBox
                position="absolute"
                top={-6}
                color={sorted === "asce" ? "text" : "secondary"}
                opacity={sorted === "asce" ? 1 : 0.5}
              >
                <Icon>arrow_drop_up</Icon>
              </MDBox>
              <MDBox
                position="absolute"
                top={0}
                color={sorted === "desc" ? "text" : "secondary"}
                opacity={sorted === "desc" ? 1 : 0.5}
              >
                <Icon>arrow_drop_down</Icon>
              </MDBox>
            </MDBox>
          )}
        </Stack>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
  isShowSortIcon: false,
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  align: PropTypes.oneOf(["left", "right", "center"]),
  isShowSortIcon: PropTypes.bool,
  isAction: PropTypes.bool,
};

export default DataTableHeadCell;
