import React from "react";
import { ItemList, ProductScope, EditOrUpdateItem } from ".";
import { Routes, Route } from "react-router-dom";
import "./style/item.style.scss";

export const Item = () => {
  return (
    <ProductScope className={"Item_modules"}>
      <Routes>
        <Route path={"/"} element={<ItemList />} />
        <Route path={"/itemDetail/edit/:id"} element={<EditOrUpdateItem />} />
        <Route path={"/itemDetail/create"} element={<EditOrUpdateItem />} />
      </Routes>
    </ProductScope>
  );
};
