import React from "react";
import { RegistrationList } from ".";
import { Routes, Route } from "react-router-dom";

export const Registration = () => {
  return (
    <Routes>
      <Route path={"/"} element={<RegistrationList />} />
      {/* <Route path={"/create"} element={<CreateOrUpdateNotification />} />
      <Route path={"/edit/:id"} element={<CreateOrUpdateNotification />} /> */}
    </Routes>
  );
};
