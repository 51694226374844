import { getCategoryList } from "..";
import { useQuery, useMutation, useQueryClient } from "react-query";

export const useCategorylistQuery = ({ enabled }) => {
  const useCategoryListQuery = useQuery(["CategoryList"], () => getCategoryList(), {
    enabled,
  });

  const { data: categoryList } = useCategoryListQuery?.data || {};

  return {
    categoryList,
    isLoading: useCategoryListQuery?.isLoading,
  };
};
