import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";

export const ConfirmModal = ({ isOpen, onConfirm, onClose, title, subTittle }) => {
  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{subTittle}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="error"
            sx={{ padding: "4px !important" }}
            onClick={onClose}
          >
            Cancel
          </MDButton>
          <MDButton variant="gradient" color="info" onClick={onConfirm}>
            ok
          </MDButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
