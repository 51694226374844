import { useEffect } from "react";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";
import { successMessage, showErrorMessage } from "utils";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getProductById,
  deleteProduct,
  updateProduct,
  createProduct,
  getProductList,
  PRODUCT_LIST,
} from "..";

const initProduct = {
  name: "",
  caret: "10",
  Weight: "",
  category_id: "",
  carrot_id: "",
  category: "1",
  active: false,
  description: "",
  product_image: "",
};

export const useItem = ({ enabled = false, id = null, setProductId }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [selectedProduct, setSelectedProduct] = useImmer(initProduct);
  const [currentProductName, setcurrentProductName] = useImmer("");
  const [deleteItemName, setDeleteItemName] = useImmer({
    category: "",
    id: null,
  });

  console.log("selectedProduct: ", selectedProduct);

  const useProductListQuery = useQuery([PRODUCT_LIST], () => getProductList(), {
    onSuccess: (data) => {},
    enabled,
    staleTime: 0,
    onSettled: () => {
      setSelectedProduct(initProduct);
    },
  });

  const useProductDetailQuery = useQuery([PRODUCT_LIST, id], () => getProductById(id), {
    onSuccess: (data) => {},
    enabled: !!id,
  });

  useEffect(() => {
    if (useProductDetailQuery.data) {
      const { data: { data: productDetail } = {} } = useProductDetailQuery;
      setSelectedProduct((draft) => {
        draft = productDetail;
        return draft;
      });
      setcurrentProductName((draft) => {
        draft = productDetail?.name;
        return draft;
      });
    }
  }, [useProductDetailQuery.data]);

  const createProducts = useMutation(createProduct, {
    onSuccess: () => {
      queryClient?.invalidateQueries([PRODUCT_LIST]);
      successMessage({ message: "Product created successfully" });
      navigate(".", { replace: true });
    },
    onError: () => {
      showErrorMessage({ message: "Product creation failed" });
    },
  });

  const updateProducts = useMutation(updateProduct, {
    onSuccess: () => {
      queryClient?.invalidateQueries([PRODUCT_LIST, id]);
      successMessage({ message: "Product updated successfully" });

      navigate(".", { replace: true });
    },
    onError: () => {
      showErrorMessage({ message: "Product updation failed" });
    },
  });

  const deleteProducts = useMutation(deleteProduct, {
    onSuccess: () => {
      queryClient?.invalidateQueries([PRODUCT_LIST]);
      onHandleDeleteItemModal({ itemName: "", id: null });
    },
    onError: () => {},
  });

  const onHandleProductCreateOrUpdate = () => {
    if (id) {
      const { product_image, ...rest } = selectedProduct;
      if (!selectedProduct?.product_image?.type) {
        updateProducts?.mutate({
          ...rest,
          _method: "PUT",
        });
      } else {
        updateProducts?.mutate({
          ...selectedProduct,
          _method: "PUT",
        });
      }
    } else {
      createProducts?.mutate(selectedProduct);
    }
  };

  const onHandleDeleteItem = () => {
    deleteProducts.mutate(deleteItemName?.id);
  };

  const onHandleDeleteItemModal = ({ itemName = "", id = null }) => {
    setDeleteItemName((draft) => {
      draft.name = itemName;
      draft.id = id;
      return draft;
    });
  };

  const onChangeProductDetail = (event, fieldName) => {
    const { name, value, checked } = event?.target;
    console.log("value: ", value);
    console.log("name: ", name);
    setSelectedProduct((draft) => {
      if (fieldName === "active") {
        draft[name] = checked ? "1" : false;
      } else {
        draft[name] = value;
        return draft;
      }
    });
  };

  const onUploadProductImage = (event) => {
    const file = event.target.files[0];
    setSelectedProduct((draft) => {
      draft.product_image = file;
      return draft;
    });
  };

  const isLoadingProductDetail = useProductDetailQuery?.isLoading;
  const isLoadingCreateProduct = createProducts?.isLoading || updateProducts?.isLoading;

  return {
    deleteItemName,
    selectedProduct,
    currentProductName,
    useProductListQuery,
    isLoadingCreateProduct,
    isLoadingProductDetail,
    setSelectedProduct,
    onHandleDeleteItem,
    onHandleDeleteItemModal,
    onUploadProductImage,
    onChangeProductDetail,
    onHandleProductCreateOrUpdate,
  };
};
