import { useSalesList } from "..";
import dayJs from "dayjs";
import { trimText } from "utils";
import MDBox from "components/MDBox";
import React, { useEffect } from "react";
import { ReactTable, Loader } from "shared";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Stack, Typography, Switch, Checkbox, Button, Grid, Card } from "@mui/material";

export const PendingApproval = () => {
  const { useSalesListQuery, setSalesStatus, onHandleApproveSalesOrder } = useSalesList({
    enabled: true,
  });
  const { data: { data: salesList } = {}, isLoading } = useSalesListQuery || {};

  useEffect(() => {
    setSalesStatus("pending");
  }, [salesList]);

  const PendingSalesStatus = ({ row }) => {
    const { id, status } = row?.original;
    const isApproved = status === "completed";
    return (
      <Stack direction={"row"} alignItems={"center"}>
        <Button
          className={`status-btn ${isApproved ? "approved" : "pending"}`}
          onClick={() => onHandleApproveSalesOrder({ id })}
        >
          {isApproved ? "Approved" : "Pending"}
        </Button>
      </Stack>
    );
  };

  const columns = [
    {
      Header: "Product ",
      accessor: "product_name",
      Cell: ({ row }) => {
        return trimText(row?.original?.product_name);
      },
    },
    {
      Header: "Price",
      accessor: "price",
    },
    {
      Header: "Gold karat",
      accessor: "carrot_id",
      Cell: ({ row }) => {
        const { carrot_id } = row?.original;
        return carrot_id === "1" ? "18 K" : "22 K";
      },
    },
    {
      Header: "Purchasee Date",
      accessor: "created_at",
      Cell: ({ row }) => {
        const { created_at } = row.original;

        const deliveryDate = dayJs(created_at)?.format("DD/MM/YYYY");

        return deliveryDate;
      },
    },
    {
      Header: "User",
      accessor: "username",
    },
    {
      Header: "Phone Number",
      accessor: "phone_number",
    },

    {
      Header: "status",
      accessor: "status",
      isNotVisibleSort: true,
      Cell: PendingSalesStatus,
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbRoute={[]} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card sx={{ pb: 3 }}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <MDTypography variant="h6" color="white">
                    Pending Approvals
                  </MDTypography>
                </Stack>
              </MDBox>
              <ReactTable
                noEndBorder
                isSorted={true}
                canSearch={true}
                showTotalEntries={true}
                entriesPerPage={{ defaultValue: 15 }}
                table={{ columns, data: salesList ? salesList : [] }}
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};
