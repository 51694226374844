import { Axios } from "utils";

export const getDistributorList = async () => {
  const res = await Axios.get(`admin/distributor`);
  return res.data;
};

export const getDistributorById = async (distributorId) => {
  const res = await Axios.get(`admin/distributor/${distributorId}`);
  return res.data;
};

export const getAreaList = async () => {
  const res = await Axios.get(`admin/area`);
  return res.data;
};

export const createDistributors = async (payload) => {
  const res = await Axios.post(`admin/distributor`, payload);
  return res.data;
};

export const updateDistributors = async (payload) => {
  const { id, ...rest } = payload;
  const res = await Axios.post(`admin/distributor/${id}`, rest);
  return res.data;
};

export const deleteDistributors = async (distributorId) => {
  const res = await Axios.delete(`admin/distributor/${distributorId}`);
  return res.data;
};
