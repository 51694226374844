import { useEffect } from "react";
import { useDelete } from "shared";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";
import { getCategoryById, createCategory, deleteCategory } from "..";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { successMessage } from "utils";

export const useCreateCategory = (id) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { deleteItem, onHandleDeleteItemDetails, resetDeleteData } = useDelete();

  const [currentCategory, setcurrentCategory] = useImmer("");
  const [selectedCategory, setSelectedCategory] = useImmer({ category: "" });

  const useCategoryDetailQuery = useQuery(["CategoryList", id], () => getCategoryById(id), {
    enabled: !!id,
  });

  useEffect(() => {
    if (useCategoryDetailQuery.data) {
      const { data: { category, ...rest } = {} } = useCategoryDetailQuery?.data || {};
      setSelectedCategory((draft) => {
        draft = {
          category: category,
          ...rest,
        };
        return draft;
      });
      setcurrentCategory((draft) => {
        draft = category;
        return draft;
      });
    }
  }, [useCategoryDetailQuery.data]);

  const createCategorys = useMutation(createCategory, {
    onSuccess: () => {
      queryClient?.invalidateQueries(["CategoryList"]);
      successMessage({ message: "Category created successfully" });
      navigate("..", { replace: true });
    },
  });

  const deleteCaregories = useMutation(deleteCategory, {
    onSuccess: () => {
      queryClient?.invalidateQueries(["CategoryList"]);
      successMessage({ message: "Category deleted successfully" });
    },
    onSettled: () => {
      resetDeleteData();
    },
  });

  const onDeleteCategory = () => {
    deleteCaregories?.mutate(deleteItem?.id);
  };

  const onHandleCreateCategory = () => {
    if (id) {
      const { id, category } = selectedCategory;
      createCategorys?.mutate({ id, category, _method: "PUT" });
    } else {
      createCategorys?.mutate(selectedCategory);
    }
  };

  const onChangeCategoryName = (event) => {
    const { value } = event?.target;
    setSelectedCategory((draft) => {
      draft.category = value;
      return draft;
    });
  };

  const isLoadingCategoryDetail = useCategoryDetailQuery?.isLoading;

  return {
    deleteItem,
    currentCategory,
    selectedCategory,
    isLoadingCategoryDetail,
    onDeleteCategory,
    resetDeleteData,
    onChangeCategoryName,
    onHandleCreateCategory,
    onHandleDeleteItemDetails,
  };
};
