import { useImmer } from "use-immer";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getDashBoard,
  getCurretItemCategorPrize,
  updateItemCategoryPrices,
  getAdminDashBoardData,
  checkUserPaymentStatus,
} from "..";
import { toGetNumberFormat, successMessage } from "utils";

const initProductCategory = {
  eighteen_carrot_rate: null,
  twenty_two_carrot_rate: null,
};

export const useDashBoard = (permission) => {
  const queryClient = useQueryClient();

  const [itemCategoryPrices, setItemCategoryPrices] = useImmer(initProductCategory);

  const isAdmin = permission === "admin";
  const isUser = permission === "user";

  const adminDashBoardData = useQuery(["ItemCategoryPrice"], getCurretItemCategorPrize, {
    enabled: isAdmin,
    staleTime: 0,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      data?.data?.map((itemDetail) => {
        setItemCategoryPrices((draft) => {
          draft = itemDetail;
          return draft;
        });
      });
    },
  });

  const adminDashBoardDetails = useQuery(["adminDashBoardData"], getAdminDashBoardData, {
    staleTime: 0,
    enabled: isAdmin,
    refetchOnWindowFocus: true,
  });

  const useUserDashboardData = useQuery(["userDashboard"], getDashBoard, {
    staleTime: 0,
    enabled: isUser,
    refetchOnWindowFocus: true,
  });

  const updtaeItemPrice = useMutation(updateItemCategoryPrices, {
    onSuccess: () => {
      successMessage({ message: "Category prices updated successfully" });
      queryClient?.invalidateQueries(["ItemCategoryPrice"]);
    },
  });

  const onHandleupdtaeItemPrice = () => {
    updtaeItemPrice?.mutate(itemCategoryPrices);
  };

  const onChangeCategoryPrices = (e) => {
    const { value, name } = e?.target;
    setItemCategoryPrices((draft) => {
      draft[name] = toGetNumberFormat(value);
      return draft;
    });
  };

  const isLoadingUserDashBoard = useUserDashboardData?.isLoading;
  const isLoadingAdminDashBoar = adminDashBoardData?.isLoading || adminDashBoardDetails?.isLoading;

  return {
    itemCategoryPrices,
    useUserDashboardData,
    isLoadingUserDashBoard,
    isLoadingAdminDashBoar,
    adminDashBoardDetails,
    onHandleupdtaeItemPrice,
    onChangeCategoryPrices,
  };
};
