import React from "react";
import { Stack } from "@mui/material";
import "../../assets/project/styles/loader.scss";

export const Loader = ({ style }) => {
  return (
    <div className="main-spinner">
      <span class="loading-spinner" style={style}></span>
    </div>
  );
};
