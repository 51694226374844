import React from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { handleNavigation, trimText } from "utils";
import MDTypography from "components/MDTypography";
import { useParams, useNavigate } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Divider, TextField, MenuItem, Switch, Stack, Card, Grid, Typography } from "@mui/material";
import { useArea } from "..";
import { HeaderSection } from "shared";

export const CreateOrUpdateArea = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { currentArea, selectedArea, onChangeAreaDetail, onHandleCreateOrUpdateArea } = useArea({
    id,
  });

  const route = [
    { navLink: "areaList", name: "Area List" },
    { name: id ? trimText(currentArea) : "Create Distributor" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbRoute={route} />
      <MDBox pt={6} pb={3}>
        <Card sx={{ px: 2, py: 3, pb: "35px" }}>
          <HeaderSection currentName={id ? currentArea : "Create Item"} />
          <Stack>
            <Grid container mt={2} spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <MDTypography variant="caption" color="text" sx={{ fontWeight: 500 }}>
                  Area*
                </MDTypography>
                <TextField
                  fullWidth
                  name="name"
                  placeholder={"Type here..."}
                  value={selectedArea?.name}
                  onChange={onChangeAreaDetail}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} display={"none"}>
                <MDTypography variant="caption" color="text" sx={{ fontWeight: 500 }}>
                  Area*
                </MDTypography>
              </Grid>
            </Grid>
          </Stack>
          <Stack position={"relative"} className="item_moduel-footer">
            <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} gap={2}>
              <MDButton
                variant="gradient"
                color="light"
                onClick={() => handleNavigation("..", navigate)}
              >
                &nbsp;Cancel
              </MDButton>
              <MDButton variant="gradient" color="info" onClick={onHandleCreateOrUpdateArea}>
                &nbsp;{id ? "update" : "Create"}
              </MDButton>
            </Stack>
          </Stack>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};
