import React from "react";
import { useInvest } from "..";
import { trimText } from "utils";
import MDBox from "components/MDBox";
import { Loader, ReactTable, LoaderButton, InputControl } from "shared";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Button, Card, Grid, Stack, TextField, Typography, Divider } from "@mui/material";

export const InvestMain = () => {
  const {
    investedAmount,
    isLoadingInvestAmount,
    isLoadingInvestMentList,
    useInvestmentHistoryList,
    onAddInvestAmount,
    onHandleInvestAmount,
  } = useInvest({ enabled: true });

  const { data: investList } = useInvestmentHistoryList?.data || {};

  const columns = [
    {
      Header: "User",
      accessor: "username",
      Cell: ({ row }) => {
        return trimText(row?.original?.username);
      },
    },
    {
      Header: "Amount",
      accessor: "amount",
      width: "20%",
    },
  ];

  if (isLoadingInvestMentList) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbRoute={[]} />
      <Card sx={{ p: 3 }}>
        <Divider sx={{ position: "absolute", width: "100%", left: "1px", top: "227px" }} />
        <MDBox pt={6} pb={3}>
          <Typography className="label_font" fontWeight={600}>
            Invet Amount*
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Stack mb={2}>
                <TextField
                  value={investedAmount}
                  onChange={onAddInvestAmount}
                  InputProps={{
                    inputComponent: InputControl,
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack position={"relative"} className="item_moduel-footer">
            <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} gap={2}>
              <LoaderButton
                btnName={"Invest"}
                isLoading={isLoadingInvestAmount}
                onClick={onHandleInvestAmount}
              />
            </Stack>
          </Stack>

          <Stack mt={3}>
            <ReactTable
              noEndBorder
              isSorted={true}
              canSearch={true}
              showTotalEntries={true}
              entriesPerPage={{ defaultValue: 15 }}
              table={{ columns, data: investList ? investList : [] }}
            />
          </Stack>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
};
