/* eslint-disable react/prop-types */
import React, { createContext } from "react";
import { useItem } from "..";
import { useImmer } from "use-immer";

export const ProductContext = createContext();

export const ProductScope = ({ children, className }) => {
  const [enabled, setEnabled] = useImmer(false);
  const [productId, setProductId] = useImmer(null);
  const product = useItem({ enabled, id: productId, setProductId });

  return (
    <ProductContext.Provider value={{ ...product, setEnabled, setProductId }}>
      <div className={className}>{children}</div>
    </ProductContext.Provider>
  );
};
