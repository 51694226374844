import { Axios } from "utils";

export const getProductSales = async ({ salesStatus }) => {
  const res = await Axios.post(`admin/get_sales?status=${salesStatus}`);
  return res.data;
};

export const approvePendingSalesStatus = async (payload) => {
  const res = await Axios.post("admin/approve_purchase", payload);
  return res.data;
};
