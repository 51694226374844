/* eslint-disable react/prop-types */

import React, { createContext } from "react";
import { useAuthenticate, useDashBoard } from "..";

export const AppContext = createContext();

export const AppScope = ({ children }) => {
  const auth = useAuthenticate();
  const { hasUserOrAdminPermission } = auth || {};

  const permission = hasUserOrAdminPermission();
  const dashBoard = useDashBoard(permission);

  return <AppContext.Provider value={{ ...auth, ...dashBoard }}>{children}</AppContext.Provider>;
};
