import React from "react";
import MDButton from "components/MDButton";

import { Button } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";

export const LoaderButton = ({
  sx,
  onClick,
  isLoading,
  btnName,
  disabled,
  className,
  isExtend,
  fullWidth,
  startIcon,
}) => {
  return (
    <MDButton
      color="info"
      variant="gradient"
      startIcon={startIcon}
      className={className}
      disabled={isLoading || disabled}
      sx={{ width: fullWidth && "100%" }}
      onClick={onClick}
    >
      {isLoading ? <CircularProgress size={20} sx={{ color: "white !important" }} /> : btnName}
    </MDButton>
  );
};
