/* eslint-disable react/prop-types */

import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import { useDistributor, useDistributorsQuery } from "..";
import { useNavigate } from "react-router-dom";
import { Stack, Grid, Card } from "@mui/material";
import { useMaterialUIController } from "context";
import { handleNavigation, trimText } from "utils";
import MDTypography from "components/MDTypography";
import { ReactTable, DeleteModal, Loader } from "shared";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

export const DistributorsList = () => {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const route = [{ name: "Distributors" }];

  const {
    deleteItem,
    useAreaList,
    useDistributorListQuery,
    resetDeleteData,
    onHandleDeleteItemDetails,
    onHandelDeleteDistributor,
  } = useDistributor({ enabled: true });

  const { data: { data: areaList } = {} } = useAreaList || {};
  const { query: data, isLoading } = useDistributorsQuery({ enabled: true });

  const DistributoArea = ({ row }) => {
    const { id, name, area_id } = row.original;
    const distributorAreaName = areaList?.find((area) => area?.id === area_id)?.name;

    return <span>{trimText(distributorAreaName)}</span>;
  };

  const ActionButton = ({ row }) => {
    const { id, name } = row.original;

    return (
      <Stack direction={"row"} alignItems={"center"} gap={1} key={`${id}_ActionButton`}>
        <MDButton
          variant="text"
          color={darkMode ? "white" : "dark"}
          sx={{ padding: "4px !important" }}
          onClick={() => {
            handleNavigation(`./distributor/edit/${id}`, navigate);
          }}
        >
          <Icon>edit</Icon>
        </MDButton>
        <MDButton
          variant="text"
          color="error"
          onClick={() => onHandleDeleteItemDetails({ name, id })}
        >
          <Icon>delete</Icon>
        </MDButton>
      </Stack>
    );
  };

  const columns = [
    {
      Header: "Distributor Name",
      accessor: "name",
      Cell: ({ row }) => {
        return trimText(row?.original?.name);
      },
    },

    {
      Header: "Distributor Area",
      accessor: "area_id",
      Cell: DistributoArea,
    },

    {
      Header: "Action",
      align: "center",
      accessor: "id",
      // width: "20%",
      isNotVisibleSort: true,
      Cell: ActionButton,
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbRoute={route} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <MDTypography variant="h6" color="white">
                    Distributors List
                  </MDTypography>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={() => handleNavigation("./distributor/create", navigate)}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;Create
                  </MDButton>
                </Stack>
              </MDBox>
              <MDBox pt={3}>
                <ReactTable
                  noEndBorder
                  isSorted={true}
                  canSearch={true}
                  showTotalEntries={true}
                  entriesPerPage={{ defaultValue: 15 }}
                  table={{ columns, data: data ? data : [] }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <DeleteModal
        text={"Distributor name"}
        itemName={deleteItem?.name}
        isOpen={deleteItem?.name}
        onConfirm={onHandelDeleteDistributor}
        handleClose={resetDeleteData}
      />
    </DashboardLayout>
  );
};
