/* eslint-disable react/prop-types */

import React from "react";
import { Loader, ProductCard } from "shared";
import MDBox from "components/MDBox";
import { useImmer } from "use-immer";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Button, Card, Grid, Stack, TextField, MenuItem } from "@mui/material";
import { useProductData, useAreaListQuery, useDistributorQuery } from "modules";
import { getPaymentStatus } from "utils";

export const ProductList = () => {
  const {
    productData,
    useProductListQuery,
    setIsOpenCheckOutModal,
    onHandlePurchaseProducts,
    onHandleProductDetails,
  } = useProductData({
    load: true,
  });

  const isDisabled = getPaymentStatus();

  const { data: { data: productList } = {}, isLoading } = useProductListQuery || {};
  const route = [{ name: "Product List" }];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbRoute={route} />
      <MDBox pt={6} pb={3}>
        <Card sx={{ width: "100% !important" }}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
              <MDTypography variant="h6" color="white">
                Product
              </MDTypography>
            </Stack>
          </MDBox>
          {/* <Stack mt={3} sx={{ borderBottom: "1px solid #e3e0e0", pb: "23px" }}>
            <Grid container spacing={2} px={4}>
              <Grid item xs={12} sm={5} md={5}>
                <TextField
                  select
                  label="Distributor"
                  name="distributor_id"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "45px !important",
                    },
                    "& .MuiSelect-select": {
                      height: "45px !important",
                    },
                  }}
                  onChange={onChangeFilterParameter}
                >
                  {isNotSelectArea && <MenuItem>Please Select Area</MenuItem>}
                  {isEmptydistributor && <MenuItem> No Distributor</MenuItem>}
                  {distributorList?.map((distributorDetail, index) => {
                    return (
                      <MenuItem key={`${index}_distributorDetail`} value={distributorDetail?.id}>
                        {distributorDetail?.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                <TextField
                  label="Area"
                  select
                  fullWidth
                  name="area_id"
                  placeholder={"Type here..."}
                  value={productFilter?.category_id}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "45px !important",
                    },
                    "& .MuiSelect-select": {
                      height: "45px !important",
                    },
                  }}
                  onChange={onChangeFilterParameter}
                >
                  <MenuItem value="">Select...</MenuItem>
                  {areaList?.map((areaDetail, index) => {
                    return (
                      <MenuItem key={`${index}areaList`} value={areaDetail?.id}>
                        {areaDetail?.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <Button variant="contained" sx={{ color: "white !important" }}>
                  Search
                </Button>
              </Grid>
            </Grid>
          </Stack> */}
          <Stack p={4}>
            <Grid container spacing={2}>
              {productList?.map((product, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={`${index}_product`}>
                    <ProductCard
                      isDisabled={isDisabled}
                      isOpen={!!productData}
                      productDetails={product}
                      onConfirm={onHandlePurchaseProducts}
                      onHandleIsOpenModal={setIsOpenCheckOutModal}
                      onHandleProductDetails={onHandleProductDetails}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};
