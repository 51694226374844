import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Divider } from "@mui/material";

export const HeaderSection = ({ currentName }) => {
  return (
    <MDBox px={2} display="flex" justifyContent="space-between" alignItems="center">
      <MDTypography variant="h6" fontWeight="medium">
        {currentName}
      </MDTypography>
      <Divider className="heder-section-divider" />
    </MDBox>
  );
};
