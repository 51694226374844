import React, { useRef, useEffect } from "react";
import { AutoPlayComponents } from ".";
import { Grid, Stack } from "@mui/material";

export const ProductAnimation = () => {
  const videoRef = useRef(null);

  return (
    <Stack mt={15}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <AutoPlayComponents
            source={
              "https://www.josalukkasonline.com/Media/jos-alukkas-Gold-Earring--JAHDYP-lSly.MP4"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AutoPlayComponents
            source={"https://www.josalukkasonline.com/Media/jos-alukkas-Gold-Ring--JA5OLO-ugt9.mp4"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AutoPlayComponents
            source={
              "https://www.josalukkasonline.com/Media/jos-alukkas-Gold-Necklace--JALCZM-LH0Q.mp4"
            }
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
