import { Axios } from "utils";

export const investAmount = async (payload) => {
  const res = await Axios.post("user/invest", payload);
  return res.data;
};

export const getInvestHistoryList = async (payload) => {
  const res = await Axios.get("user/invest-history");
  return res.data;
};

// export const purchaseProduct = async (payload) => {
//   const res = await Axios.post("/user/purchase_item", payload);
//   return res.data;
// };
