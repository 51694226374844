import React, { useEffect, useRef } from "react";
import MDBox from "components/MDBox";
import { useImmer } from "use-immer";
import { fileuploadicon } from "assets";
import MDButton from "components/MDButton";
import { useProductScopeContext } from "..";
import { useCategorylistQuery } from "modules";
import { handleNavigation, trimText } from "utils";
import MDTypography from "components/MDTypography";
import { HeaderSection, LoaderButton, Loader, InputControlV2 } from "shared";
import { useParams, useNavigate } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import {
  Grid,
  Box,
  Card,
  Stack,
  Button,
  Switch,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

export const EditOrUpdateItem = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [isShowChangeBtn, setIsShowBtn] = useImmer(false);

  const {
    setProductId,
    selectedProduct,
    currentProductName,
    setSelectedProduct,
    isLoadingCreateProduct,
    isLoadingProductDetail,
    onChangeProductDetail,
    onHandleProductCreateOrUpdate,
  } = useProductScopeContext();
  const carrotList = [
    { name: "18 k", id: "1" },
    { name: "22 k", id: "2" },
  ];

  const itemImage = id
    ? selectedProduct?.product_image?.type
      ? URL.createObjectURL(selectedProduct?.product_image)
      : selectedProduct?.product_image
    : selectedProduct?.product_image && URL.createObjectURL(selectedProduct?.product_image);

  const { categoryList } = useCategorylistQuery({ enabled: true });

  const route = [
    { navLink: "itemList", name: "Item List" },
    { name: id ? trimText(currentProductName) : "Create Item" },
  ];

  useEffect(() => {
    if (id) {
      setProductId(id);
    }
  }, []);

  const handleButtonClick = (type) => {
    fileInputRef.current.click();
  };

  const uploadItemImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedProduct((draft) => {
        draft.product_image = file;
        return draft;
      });
      event.target.value = null;
    }
  };

  if (isLoadingProductDetail) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbRoute={route} />
      <MDBox pt={6} pb={3}>
        <Card sx={{ px: 2, py: 3, pb: "35px" }}>
          <HeaderSection currentName={id ? trimText(currentProductName) : "Create Item"} />
          <Stack>
            <Grid container mt={2} spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <MDTypography variant="caption" color="text" sx={{ fontWeight: 500 }}>
                  Item Name*
                </MDTypography>
                <TextField
                  fullWidth
                  name="name"
                  placeholder={"Type here..."}
                  value={selectedProduct?.name}
                  onChange={onChangeProductDetail}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MDTypography variant="caption" color="text" sx={{ fontWeight: 500 }}>
                  Category*
                </MDTypography>
                <TextField
                  select
                  fullWidth
                  name="category_id"
                  placeholder="Type here..."
                  value={selectedProduct?.category_id}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "45px !important",
                    },
                    "& .MuiSelect-select": {
                      height: "45px !important",
                    },
                  }}
                  onChange={onChangeProductDetail}
                >
                  <MenuItem value="">Select</MenuItem>
                  {categoryList?.map((categoryDetail, index) => (
                    <MenuItem key={`${index}_categoryList`} value={categoryDetail?.id}>
                      {categoryDetail?.category}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MDTypography variant="caption" color="text" sx={{ fontWeight: 500 }}>
                  karat*
                </MDTypography>
                <InputControlV2
                  type={"dropdown"}
                  option={carrotList}
                  name="carrot_id"
                  value={selectedProduct?.carrot_id}
                  onChange={onChangeProductDetail}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MDTypography variant="caption" color="text" sx={{ fontWeight: 500 }}>
                  Weight*
                </MDTypography>
                <TextField
                  fullWidth
                  name="Weight"
                  placeholder={"Type here..."}
                  value={selectedProduct?.Weight}
                  onChange={onChangeProductDetail}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <MDTypography variant="caption" color="text" sx={{ fontWeight: 500 }}>
                  Description*
                </MDTypography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  name="description"
                  placeholder={"Type here..."}
                  value={selectedProduct?.description}
                  onChange={onChangeProductDetail}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <MDTypography variant="caption" color="text" sx={{ fontWeight: 500 }}>
                  Image*
                </MDTypography>
                <input
                  type="file"
                  onChange={uploadItemImage}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <Stack
                  height={200}
                  position={"relative"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  onMouseEnter={() => setIsShowBtn(true)}
                  onMouseLeave={() => setIsShowBtn(false)}
                  sx={{ borderStyle: "dashed", borderColor: "#cacaca" }}
                >
                  {selectedProduct?.product_image ? (
                    <>
                      <Box
                        component={"img"}
                        sx={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          objectPosition: "center",
                        }}
                        src={itemImage}
                      />
                      <Button
                        variant="outlined"
                        sx={{
                          bottom: 10,
                          fontSize: 13,
                          position: "absolute",
                          background: " #bcbcbc78",
                          color: "black !important",
                          display: isShowChangeBtn ? "block" : "none",
                          "&:hover": {
                            background: " #bcbcbc78",
                          },
                        }}
                        onClick={handleButtonClick}
                      >
                        <i className="bi bi-pen"></i>
                        Change image
                      </Button>
                    </>
                  ) : (
                    <Stack
                      alignItems={"center"}
                      sx={{ cursor: "pointer" }}
                      onClick={handleButtonClick}
                    >
                      <Box component={"img"} src={fileuploadicon} height={40} width={40} />
                      <Typography fontSize={"12px"} fontWeight={500}>
                        Upload file
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={12} className="active-switch">
                <MDBox display="flex" alignItems="center" mb={0.5} className="switchButton">
                  <MDBox ml={0.5}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ fontWeight: 500 }}
                    >
                      Active
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <Switch
                      name="active"
                      checked={selectedProduct?.active === "1"}
                      onChange={(event) => onChangeProductDetail(event, "active")}
                    />
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </Stack>
          <Stack position={"relative"} className="item_moduel-footer" mt={3}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} gap={2}>
              <MDButton
                variant="gradient"
                color="light"
                onClick={() => handleNavigation("..", navigate)}
              >
                &nbsp;Cancel
              </MDButton>
              <LoaderButton
                isLoading={isLoadingCreateProduct}
                btnName={id ? "update" : "Create"}
                onClick={onHandleProductCreateOrUpdate}
              />
            </Stack>
          </Stack>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};
