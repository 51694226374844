import { Axios } from "utils";

export const getProductList = async () => {
  const res = await Axios.get("admin/products");
  return res.data;
};

export const getProductById = async (productId) => {
  const res = await Axios.get(`admin/products/${productId}`);
  return res.data;
};

export const createProduct = async (payload) => {
  const res = await Axios.post(`admin/products`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const updateProduct = async (payload) => {
  const { id } = payload;
  const res = await Axios.post(`admin/products/${id}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const deleteProduct = async (productId) => {
  const res = await Axios.delete(`admin/products/${productId}`);
  return res.data;
};
