import { Stack, Button, Typography } from "@mui/material";

export const StatusBtn = ({ value, onClick, isLabel }) => {
  const { id, status } = value?.row?.original || {};
  const isApproved = status === "approved";

  return (
    <Stack direction={"row"} alignItems={"center"}>
      {isLabel ? (
        <Typography
          p={1}
          fontSize={"13px"}
          className={`status-btn ${isApproved ? "approved" : "pending"}`}
        >
          {isApproved ? "Approved" : "Pending"}
        </Typography>
      ) : (
        <Button
          className={`status-btn ${isApproved ? "approved" : "pending"}`}
          onClick={() => onClick(id)}
        >
          {isApproved ? "Approved" : "Pending"}
        </Button>
      )}
    </Stack>
  );
};
