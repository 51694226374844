import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { InputControl, Loader, ProductCard } from "shared";
import { useAppScopeContext, useDashBoard } from "master";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { Button, Card, Stack, TextField, Typography } from "@mui/material";
import { useProductData } from "modules";
import { getPaymentStatus } from "utils";
import { ModalComponent } from "shared";

function Dashboard() {
  const isDisabled = getPaymentStatus();

  const {
    itemCategoryPrices,
    useUserDashboardData,
    isLoadingUserDashBoard,
    isLoadingAdminDashBoar,
    adminDashBoardDetails,
    hasUserOrAdminPermission,
    onChangeCategoryPrices,
    onHandleupdtaeItemPrice,
  } = useAppScopeContext();

  const {
    productData,
    useProductListQuery,
    setIsOpenCheckOutModal,
    onHandlePurchaseProducts,
    onHandleProductDetails,
  } = useProductData({
    load: true,
    fromDahBoard: true,
  });

  const { data: { data: productList } = {}, isLoading } = useProductListQuery || {};

  const { amount_purchased, total_invest, user_balance } = useUserDashboardData?.data || {};

  const userData = adminDashBoardDetails?.data || {};

  const isAdmin = hasUserOrAdminPermission() === "admin";

  const isUser = hasUserOrAdminPermission() === "user";

  const title = isAdmin && !isUser ? "Users count" : !isAdmin && isUser ? "Total Investment" : "";

  const title1 =
    isAdmin && !isUser ? "Total Purchases" : !isAdmin && isUser ? " Available Balance" : "";

  const title2 =
    isAdmin && !isUser ? "Total Pending Purchases" : !isAdmin && isUser ? "Purchase Amount" : "";

  const amount = !isAdmin && isUser ? total_invest : userData?.data?.users_count;

  const amount1 = !isAdmin && isUser ? user_balance : userData?.data?.total_purchses;

  const amount2 = !isAdmin && isUser ? amount_purchased : userData?.data?.total_pending_purchases;

  if (isLoadingUserDashBoard || isLoadingAdminDashBoar) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard color="dark" icon="weekend" title={title} count={amount} />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard icon="leaderboard" title={title1} count={amount1} />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard color="success" icon="store" title={title2} count={amount2} />
            </MDBox>
          </Grid>
        </Grid>
        {isAdmin && (
          <Card sx={{ p: 3, mb: 3 }}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item md={5} sm={5} xs={12}>
                <Stack>
                  <Typography fontSize={13} fontWeight={600}>
                    18 karat*
                  </Typography>
                  <TextField
                    placeholder="Type here..."
                    name="eighteen_carrot_rate"
                    value={itemCategoryPrices?.eighteen_carrot_rate}
                    onChange={onChangeCategoryPrices}
                    fullWidth
                    InputProps={{
                      inputComponent: InputControl,
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item md={5} sm={5} xs={12}>
                <Stack>
                  <Typography fontSize={13} fontWeight={600}>
                    22 karat*
                  </Typography>
                  <TextField
                    placeholder="Type here..."
                    name="twenty_two_carrot_rate"
                    value={itemCategoryPrices?.twenty_two_carrot_rate}
                    onChange={onChangeCategoryPrices}
                    fullWidth
                    InputProps={{
                      inputComponent: InputControl,
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={2} sm={2} pt={"36px !important"}>
                <Button
                  variant="contained"
                  sx={{ color: "white !important" }}
                  onClick={onHandleupdtaeItemPrice}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Card>
        )}
        {isUser && (
          <Stack p={4}>
            <Grid container spacing={2}>
              {productList?.map((product, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={`${index}_product`}>
                    <ProductCard
                      showBuyNow={true}
                      isOpen={!!productData}
                      isDisabled={isDisabled}
                      productDetails={product}
                      onConfirm={onHandlePurchaseProducts}
                      onHandleIsOpenModal={setIsOpenCheckOutModal}
                      onHandleProductDetails={onHandleProductDetails}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        )}
        <ModalComponent
          isOpen={!!productData}
          onConfirm={onHandlePurchaseProducts}
          handleClose={() => onHandleProductDetails({ productDetails: null })}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
