import { useImmer } from "use-immer";
import { investAmount, getInvestHistoryList } from "..";
import { successMessage, showErrorMessage, toGetNumberFormat } from "utils";

import { useQuery, useMutation, useQueryClient } from "react-query";

export const useInvest = ({ enabled }) => {
  const queryClient = useQueryClient();

  const [investedAmount, setInvestestAmount] = useImmer(null);

  const useInvestmentHistoryList = useQuery(["investmentList"], getInvestHistoryList, {
    enabled,
  });

  const onAddInvestAmount = (e) => {
    const { value } = e?.target;
    setInvestestAmount((draft) => {
      draft = toGetNumberFormat(value);
      return draft;
    });
  };

  const onInvestAmount = useMutation(investAmount, {
    onSuccess: (data) => {
      const { data: paymetGateWayData } = data || {};
      if (paymetGateWayData) {
        const paymentObject = new window.Razorpay(paymetGateWayData);
        paymentObject.open();
      }
      setInvestestAmount("");
      queryClient?.invalidateQueries(["investmentList"]);
    },
    onError: () => {
      showErrorMessage({ message: "Error: Investment faild" });
    },
  });

  const onHandleInvestAmount = () => {
    onInvestAmount?.mutate({
      amount: investedAmount,
    });
  };

  const isLoadingInvestAmount = onInvestAmount?.isLoading;
  const isLoadingInvestMentList = useInvestmentHistoryList?.isLoading;

  return {
    investedAmount,
    isLoadingInvestAmount,
    isLoadingInvestMentList,
    useInvestmentHistoryList,
    onAddInvestAmount,
    onHandleInvestAmount,
  };
};
