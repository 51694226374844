import { useQuery } from "react-query";
import { getProductList, PRODUCT_LIST } from "..";

export const useItemListQuery = ({ enabled }) => {
  const useProductListQuery = useQuery([PRODUCT_LIST], () => getProductList(), {
    onSuccess: (data) => {},
    enabled,
  });

  const { data: { data: productList } = {}, isLoading } = useProductListQuery || {};

  return { query: productList, isLoading };
};
